import React, { useEffect, useState } from "react";
import AdminHeader from "./AdminHeader";
import Sidebar from "./Sidebar";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
// import DataTable  from '@ashvin27/react-datatable';
import { Bar } from "react-chartjs-2";
import flight from "../../Assets/images/flight.svg";
import activity from "../../Assets/images/activity.svg";
import hotel from "../../Assets/images/hotel.svg";
import { PiArrowLineRightBold } from "react-icons/pi";
import { PiArrowLineLeftBold } from "react-icons/pi";
import { FaArrowRight } from "react-icons/fa";
import { IoReload } from "react-icons/io5";

import { useHistory } from "react-router-dom";
import { IoBedOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import room1 from "../../Assets/images/room1.jpg";
import user from "../../Assets/images/user.jpg";
import { AllHotelDetails, Booking_HotelDetails, UserReviews } from "../../Axios/admin.axios";
import config from '../../config/config'
import { DateTimeForm, Name_showing, formatNumber } from "../../CommonHooks/commenfunctions";
const DashboardInner = () => {
  const { push } = useHistory();
  const [selectedDate, setSelectedDate] = useState(null);
  const [loadIcon, setLoadIcon] = useState(false);
  const [Hotelscount, setHotelscount] = useState({});
  const [TodayHotelscount, setTodayHotelscount] = useState({});

  var obj = {
    loader: true, Hotelsdata: []
   };
  const [Hotels, setHotels] = useState(obj);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [Reviews, setReviews] = useState(5);

  

useEffect(()=>{
  BookingDetails()
  TodayBookingDetails()
  GetUserReviews()

},[])





useEffect(()=>{

  setHotels(obj)
  BookingSchedule({
    Starttime:new Date(new Date(Date.now())?.setHours(0,0,0,0)),
    Endtime:new Date (new Date(new Date(Date.now())?.setHours(0,0,0,0)).setDate(new Date(new Date(Date.now())?.setHours(0,0,0,0)).getDate()+1)),
    page:page})

},[page])

  
const BookingDetails=async()=>{
        let { success,data } = await AllHotelDetails({
          Starttime: new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate()-3 )),
          Type:"counts"

        });
        setHotelscount(data?.[0])    
    }


    const TodayBookingDetails=async()=>{
      let { success,data } = await AllHotelDetails({
        Starttime: new Date(new Date(Date.now())),
        Type:"counts"

      });
      setTodayHotelscount(data?.[0])    
  }

   const BookingSchedule =async({Starttime,Endtime,page})=>{
          let { success,data } = await AllHotelDetails({
          Starttime: Starttime,
          Endtime:Endtime,
          Type:"selctdate",
          Limit:limit,
          Skip:((page ? parseInt(page) : 1) - 1) * limit
          
        });
        setHotels({
          ...Hotels,
          ...{
              Hotelsdata:page==1? data:[...(Hotels?.Hotelsdata ?? [ ]), ...data],
              loader:data?.length == 0 ? false : true,
  
          },
        });
   }

   const GetUserReviews=async()=>{
    let { success,data } = await UserReviews({limit:10});
    setReviews(data)
   }

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont dash_board">
          <h4 className="adminblue my-4">Hotels</h4>
          <p className="heading_txt">Recent Hotel Details</p>

          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
              <div className="hotel_card">
                <div className="booking-status d-flex align-items-center">
                  <span className="icon">
                    <IoBedOutline />
                  </span>
                  <div className="details">
                    <h4 className="count mb-0">{Hotelscount?.NewBooking??0}</h4>
                    <p className="name mb-0">New Booking</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
              <div className="hotel_card">
                <div className="booking-status d-flex align-items-center">
                  <span className="icon">
                    <IoBedOutline />
                  </span>
                  <div className="details">
                    <h4 className="count mb-0">{Hotelscount?.CancelBooking??0}</h4>
                    <p className="name mb-0">Cancel Booking</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
              <div className="hotel_card">
                <div className="booking-status d-flex align-items-center">
                  <span className="icon">
                    <PiArrowLineRightBold />
                  </span>
                  <div className="details">
                    <h4 className="count mb-0">{Hotelscount?.Checkin??0}</h4>
                    <p className="name mb-0">Check In</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
              <div className="hotel_card">
                <div className="booking-status d-flex align-items-center">
                  <span className="icon">
                    <PiArrowLineLeftBold />
                  </span>
                  <div className="details">
                    <h4 className="count mb-0">{Hotelscount?.Checkout??0}</h4>
                    <p className="name mb-0">Check Out</p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
              <div className="hotel_card">
                <div className="booking-status d-flex align-items-center">
                  <span className="icon">
                    <IoBedOutline />
                  </span>
                  <div className="details">
                    <h4 className="count mb-0">4,321</h4>
                    <p className="name mb-0">New Booking</p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row mt-4">
            <div className="col-lg-6 mb-3">
              <div className="recent_bookings">
                <p className="heading_txt">Recent Booking Schedule</p>
                <div className="cmn_datePicker mt-3 pt-2">
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => {  setHotels(obj);
                      BookingSchedule({Starttime:new Date(new Date(date)?.setHours(0,0,0,0)),
                        Endtime:new Date(new Date(new Date(date)?.setHours(0,0,0,0)).setDate(new Date(new Date(date)?.setHours(0,0,0,0)).getDate()+1)),
                        page:page})
                    }}
                    inline
                  />
                </div>
                <hr className="mb-0 mt-0 btn_bdr" />
  { Hotels?.Hotelsdata?.length >0?             
  <div className="booked_rooms_list mt-3">
                  { Hotels?.Hotelsdata?.map((item, index) => {
                    return (
                      <div className="room_user_info mb-3">
                        <div className="room">
                          <img
                            src={item.Images?.url??room1}
                            className="img-fluid roomImg"
                          />
                          <div>
                            <p className="room_type fw-500">{item?.RoomType}</p>

                            <div className="userInfo mt-2">
                              <img
                                src={`${config?.IMG_URL}/user/${item?.User?._id}/profile/${item?.User?.Profile}`}
                                className="img-fluid userImg"
                              />
                              <p className="mb-0 name">{Name_showing(item?.User?.FirstName+item?.User?.LastName)}</p>
                              <p className="mb-0 time">{DateTimeForm({date:item?.createdAt, words:true})}</p>
                            </div>
                          </div>
                        </div>

                        {/* <p className="mb-0 mt-2 booked_date">
                          {item.date.join(", ")}
                        </p> */}
                      </div>
                    );
                  })}
{ Hotels?.loader&&
                  <p
                    className="view_moreBtn mb-0 text-center f-15 d-flex align-items-center justify-content-center"
                    onClick={()=>setPage(page+1)}
                  >
                    View More
                    {loadIcon && <IoReload className="ms-1 loadIcon" />}
                  </p>
                  }
                </div>
                :
                <>
                No Data Found
                </>
                
                
                }
              </div>
            </div>
            <div className="col-lg-6">
              <div className="room_details_info">
                <div className="available_rooms cmn_rm">
                  <div className="rooms_count">
                    <div>
                      <p className="mb-0">Booked Rooms</p>
                      <p className="mb-0">Today</p>
                    </div>
                    <p className="mb-0 value">{TodayHotelscount?.NewBooking}</p>
                  </div>
                  <ProgressBar now={formatNumber((parseInt(TodayHotelscount?.NewBooking)/1000)*100)}  />
                </div>
                <div className="sold_rooms cmn_rm">
                  <div className="rooms_count">
                    <div>
                      <p className="mb-0">Cancelled Rooms</p>
                      <p className="mb-0">Today</p>
                    </div>
                    <p className="value mb-0">{TodayHotelscount?.CancelBooking}</p>
                  </div>
                  <ProgressBar now={formatNumber((parseInt(TodayHotelscount?.NewBooking)/1000)*100)} />
                </div>
              </div>
              <div className="total_rooms_cnt mt-4">
                <div className="room_total">
                  <div className="item text-center">
                    <p className="cnt mb-2 ">{formatNumber(Hotelscount?.TotalBookings??0)}</p>
                    <p className="mb-0 label_txt">{Hotelscount?.TotalBookings??0}</p>
                    <p className="mb-0 label_txt">Total Bookings</p>
                  </div>

                  <div className="item text-center">
                    <p className="cnt mb-2 ">{formatNumber(Hotelscount?.TotalCancels??0)}</p>
                    <p className="mb-0 label_txt">{Hotelscount?.TotalCancels??0}</p>

                    <p className="mb-0 label_txt">Total Cancels</p>
                  </div>

                  <div className="item text-center">
                    <p className="cnt mb-2 ">{formatNumber(Hotelscount?.TotalCommissions??0)}</p>
                    <p className="mb-0 label_txt">{Hotelscount?.TotalCommissions??0}</p>

                    <p className="mb-0 label_txt">Total Commissions</p>
                  </div>

                  <div className="item text-center">
                    <p className="cnt mb-2 ">{formatNumber(Hotelscount?.TotalTransaction??0)}</p>
                    <p className="mb-0 label_txt">{Hotelscount?.TotalTransaction??0}</p>

                    <p className="mb-0 label_txt">Total Transaction</p>
                  </div>
                </div>
                <div className="annual_report mt-4">
                  <div>
                    <p className="mb-1 f-15 fw-500">
                      Let Travl Generate Your Annualy Report Easily
                    </p>
                    <p className="des mb-0 f-12 textgray">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labo
                    </p>
                  </div>
                  <FaArrowRight className="arrow_icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardInner;
