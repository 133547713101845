
let key = {};
var Back_Url = "";
var FRONT_URL = "";
var IMG_URL = "";
var EnvName = "demo";
var ADMIN_URL=''




if (EnvName === "local") {
  FRONT_URL = "http://localhost:3000";
  Back_Url = "http://localhost:3331";
  IMG_URL="http://localhost:3331";


  
}
if (EnvName === "demo") {
  FRONT_URL = "https://pietrip.maticz.in";
  Back_Url = "https://pietripbackend.maticz.in";
  IMG_URL="https://pietripbackend.maticz.in";
  ADMIN_URL='https://pietripadmin.maticz.in'

}
key = {
  FRONT_URL:FRONT_URL,
  Back_Url: `${Back_Url}`,
  IMG_URL:IMG_URL,
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  MOBILE: /^\d{10}$/,
  OTP: /^\d{6}$/,
  NumOnly: /^[1-9]\d*(\.\d+)?$/,
  PASSWORD:/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
  PANCARD:/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  PASSPORT:/^[A-PR-WYa-pr-wy][1-9]\d\s?\d{4}[1-9]$/,
  SECRET_KEY: "trippie",



};

export default key;