import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; 
import 'bootstrap/js/src/modal.js';
import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/tooltip.js';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from 'react-router-dom';
import { LoadToast, updateToast, validation } from '../../../CommonHooks/commenfunctions';
import { Faq, Faqcategory } from '../../../Axios/admin.axios';
import { isEmpty } from 'validator';
import Select from "react-select";


const FormView = () =>{
    
    const initial = {
    Question: "",
    Category:"",
    Type:"add",

  };
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  const [Answer, setAnswer] = useState("");
  const [faqcat , setFaqcat] = useState([]);


  const { push } = useHistory();
  const location =useLocation()

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#653883"
          : isSelected
          ? "#653883"
          : isFocused
          ? "#653883"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "38px",
      padding: "0px 10px",
      border: "none",
      backgroundColor: "#fff",
      color: "#fff",
      borderColor: "#BEBEBE",
      borderRadius: "4px",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: "4px",
      backgroundColor: "transparent",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#000",
      };
    },
  };


  const onchange = (id,value) => {
    setErr({})
    setFormdata({ ...formdata, ...{ [id]: value } });
  };

  const handlesubmit = async () => {
    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setloader(false)
      return;
    }
    if (isEmpty(Answer)) {
        updateToast(id, "fix");
        setErr({Answer:"Answer is required"});
        setloader(false)
  
        return;
      }

    setErr({});

    formdata.Answer=Answer

    let { status,msg,data } = await Faq(formdata);

    updateToast(
      id,
      msg,
      status
    );

    if (status=='success') {
        push("/faq");

       } 
  
       else{
        setErr(err);
        setloader(false)


       }

  };

  useEffect(()=>{
    Getallfaqcategories()
  },[])
  
  const Getallfaqcategories=async()=>{
    let {status,data}=await Faqcategory({Type:"get"})
    let arr=[]
    data?.map((itm)=>{
      console.log("itmitm",itm);
      arr.push({label:itm?.Name,value:itm?.Name})
    })

    setFaqcat(arr)

  }

    return(
        <div id="." className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Edit FAQ</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>

                        

                        { 
                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Question"
                                className="mb-3" >
                            <Form.Control type="text" disabled={false}
placeholder="name@example.com" value={formdata?.Question} onChange={(e)=>onchange('Question',e.target.value)}
 />
                            </FloatingLabel>
                            {err?.Question && (
                      <p className="text-left err_msg_txt">{err?.Question}</p>
                    )}
                        </div>
                        }
             <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">
                
       

<Select
                                      onChange={(e) =>
                                        setFormdata({...formdata,...{['Category']:e?.value}})  
                                          }
                                      options={faqcat}
                                      styles={stylesgraybg}
                                      isSearchable={false}
                                      placeholder="Select Category"
                                      classNamePrefix="customselect"
                                      value={{
                                        value: formdata?.Category,
                                        label: formdata?.Category
                                      }}
                             
                                    />
                                    

        
                {err?.Category && (
                      <p className="text-left err_msg_txt">{err?.Category}</p>
                    )}

              </div>
              <div className="px-4">
                <h5 className="mb-3">Answer *:</h5>

                <ReactSummernote
                  value={Answer}
                  options={{
                    //   lang: 'ru-RU',
                    height: 350,
                    dialogsInBody: true,
                    toolbar: [
                      ["style", ["style"]],
                      ["font", ["bold", "underline", "clear"]],
                      ["fontname", ["fontname"]],
                      ["para", ["ul", "ol", "paragraph"]],
                      ["table", ["table"]],
                      ["insert", ["link", "picture", "video"]],
                      ["view", ["fullscreen", "codeview"]],
                    ],
                  }}
                  onChange={(e) => {
                    setAnswer(e);
                  }}
                />

              </div>
              {err?.Answer && (
                      <p className="text-left err_msg_txt">{err?.Answer}</p>
                    )}
                      
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/manage-cms")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}


export default FormView