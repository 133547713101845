import {
  Route,
  Redirect
} from 'react-router-dom';
import Adminlogin from './Components/Admin/Login';
function adminRoute({ children, email, ...rest  }) {
  return (
    <Route
      render={
        ({ location }) => (
          (email) ? ( // all page
            // rest.path == '/login' ? children :  <Redirect to="/"></Redirect>
            children
          ) :( ! email &&
            
            <Redirect to="/login"></Redirect>
          )
         )
      }
      {...rest}

    />
  );
}

export default adminRoute;