import React from "react";
import AdminHeader from "./AdminHeader";
import Sidebar from "./Sidebar";
import flight from "../../Assets/images/flight.svg";
import activity from "../../Assets/images/activity.svg";
import hotel from "../../Assets/images/hotel.svg";
import "react-datepicker/dist/react-datepicker.css";

import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const Dashboard = () => {
  const { push } = useHistory();

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont dash_board">
          <h4 className="adminblue my-4">Dashboard</h4>
        
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
              <Link to="/dashboard/hotel" className="text-decoration-none">
                <div className="cusCard">
                  <p className="heading_txt">Hotel</p>
                  <div className="image">
                    <img src={hotel} className="img-fluid mt-3" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
              <div className="cusCard">
                <p className="heading_txt">Activity</p>
                <div className="image">
                  <img src={activity} className="img-fluid mt-3" />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              <div className="cusCard">
                <p className="heading_txt">Flight</p>
                <div className="image">
                  <img src={flight} className="img-fluid mt-3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
