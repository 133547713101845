import React, { useEffect, useState, Fragment } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";
import DataTable from "@ashvin27/react-datatable";

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Booking_HotelDetails } from "../../../Axios/admin.axios";

import Modal from "react-bootstrap/Modal";
import profile from "../../../Assets/images/user.jpg"
import { DateTimeForm } from "../../../CommonHooks/commenfunctions";

const Hotels = () => {

  // const navigate = useNavigate();
  const { push } = useHistory();
  const location = useLocation();
  const [status, setStatus] = useState(false);
  const [Tabname, setTabname] = useState("All");
  const [Searchdata, setSearchdata] = useState("");
  const [hotelModalData, setHotelModalData] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (val) => {
    setHotelModalData(val);
    setShow(true);
  };
  var obj = {
    ["All"]: { loader: true, page: 1, list: [] },
  };
  const [Bookingdetails, setBookingdetails] = useState(obj);



  useEffect(()=>{
      Bookingdetails[Tabname] = { page: 1, list: [], loader: false };
      setBookingdetails(Bookingdetails);
      BookingDetails(1, Tabname);
    
  },[Tabname])


  const BookingDetails = async (page, tab) => {
    let Arr = [];
    let { success, data } = await Booking_HotelDetails({
      BookingStatus: tab,
      Searchdata: Searchdata,
      // limit:10,
      // skip:((page ? parseInt(page) : 1) - 1) * 10,
    });
    if (data) {
      setBookingdetails({
        ...Bookingdetails,
        ...{
          [tab]: {
            list: [...(Bookingdetails[tab]?.list ?? []), ...data],
            loader: data?.length == 0 ? false : true,
            page: Bookingdetails[tab]?.page,
          },
        },
      });
    }

    // push('/account')
  };

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Hotels",
    no_data_text: "No hotels found!",
    button: {
      excel: true,
      print: true,
      csv: true,
    },
    className: "table",
    language: {
      length_menu: "Show _MENU_",
      filter: "Filter Hotels",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "<<",
        previous: "<",
        next: ">",
        last: ">>",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };




  const pageChange = async (index) => {
    try {
      console.log("index_dddata", index);
      let reqData = {
        page: index.page_number,
        limit: index.page_size,
        search: index.filter_value,
      };
      // await getalluserhook(reqData).then((result) => {
        // console.log("get all user result", result);
        // (Bookingdetails[Tabname]?.list).map((a, i) => {
        //   a.serial = i + 1;
        // });
        // setUsers(result?.data?.data?.record);
      // });
      console.log("index_data", index);
    } catch (err) {
      console.log("pageChange_err", err);
    }
  };

  const Columns = [
    {
      key: "serial",
      text: "Id",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record, index) => <div>{index + 1}</div>,
    },
    {
      key: "Hotel",
      text: "Hotel",
      className: "",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <div
            className="details cursor-pointer"
            onClick={() => handleShow(record)}
          >
     {      !record?.Images?.url
     ?
            <img
              src={profile}
              alt="user"
              className="img-fluid hotelTable Img"
            />
            :
            <img
              src={record?.Images?.url}
              alt="user"
              className="img-fluid hotelTable Img"
            />
            }
          </div>
        );
      },
    },
    {
      key: "HotelName",
      text: "Hotel Name",
      className: "guest_Table_data",
      align: "left",
      sortable: true,
    },
    {
      key: "date",
      text: "Booking Date",
      className: "date_order",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <div className="val">
            {DateTimeForm({ date: record.createdAt, words: true })}
          </div>
        );
      },
    },

    {
      key: "checkin",
      text: "Check In",
      className: "check_In_Out",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <div className="val">
            <p className="mb-0 date">
              {DateTimeForm({ date: record.Checkin, words: true })}
            </p>
          </div>
        );
      },
    },
    {
      key: "checkout",
      text: "Check Out",
      className: "check_In_Out",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <div className="val">
            <p className="mb-0 date">
              {DateTimeForm({ date: record.Checkout, words: true })}
            </p>
          </div>
        );
      },
    },

    {
      key: "RoomType",
      text: "Room Type",
      className: "",
      align: "left",
      //   sortable: true,
    },
    {
      key: "BookingType",
      text: "Room Type",
      className: "",
      align: "left",
      //   sortable: true,
    },
    {
      key: "Paymentmethod",
      text: "Payment Method",
      className: "",
      align: "left",
      //   sortable: true,
    },

    {
      key: "Amount",
      text: "Hotel Price(INR)",
      className: "",
      align: "left",
      //   sortable: true,
    },
    {
      key: "TotalAmount",
      text: "Price With Commission(INR)",
      className: "",
      align: "left",
      //   sortable: true,
    },
    {
      key: "Commission",
      text: "Commission(INR)",
      className: "",
      align: "left",
      //   sortable: true,
    },
    {
      key: "PaymentStatus",
      text: "Payment Status(INR)",
      className: "",
      align: "left",
      //   sortable: true,
    },

    {
      key: "BookingStatus",
      text: "BookingStatus",
      className: "status",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <div className="">
            <button
              className={
                record?.BookingStatus == "Pending"
                  ? "cmnBtn pending"
                  : record?.BookingStatus == "Refund"
                  ? "cmnBtn refund"
                  : record?.BookingStatus == "Completed"
                  ? "cmnBtn booked"
                  : "cmnBtn cancelled"
              }
            >
              {record?.BookingStatus}
            </button>
          </div>
        );
      },
    },
    {
      key: "review",
      text: "Reviews",
      className: "reviews",
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <div className="">
              <button className="btn f-12 cmn-btn" onClick={()=>push('/hotels/review',{ data: record?.HotelId })}>Review</button>
          </div>
        );
      },
    },
    
  ];

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Hotels</h4>
          {/* <button className='btn btn-theme me-3' onClick={()=>{push("/admin/adduser")}}>Add</button> */}

          <div className="tablesec shadowbox px-3 mt-3 br-10 mb-5">
            <div className="hotels_guestDetails">
              <div
                className={Tabname == "All" ? "list active" : "list"}
                onClick={() => setTabname("All")}
              >
                All Bookings
              </div>
              <div
                className={Tabname == "Pending" ? "list active" : "list"}
                onClick={() => setTabname("Pending")}
              >
                Pending
              </div>
              {/* <div
                className={Tabname == "Booked" ? "list active" : "list"}
                onClick={() => setTabname("Booked")}
              >
                Booked
              </div> */}
              <div
                className={Tabname == "Cancelled" ? "list active" : "list"}
                onClick={() => setTabname("Cancelled")}
              >
                Cancelled
              </div>
              <div
                className={Tabname == "Refund" ? "list active" : "list"}
                onClick={() => setTabname("Refund")}
              >
                Refund
              </div>
            </div>
            <div className="hotels_datas">
              {/* All Guest datas */}

              <DataTable
                striped
                responsive
                config={config}
                className={config?.className}
                records={Bookingdetails[Tabname]?.list}
                columns={Columns}
                // dynamic={true}
                // onChange={pageChange}
              />
            </div>
          </div>
        </div>
      </div>

      {/* modal */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="hotel_customModal"
      >
        {hotelModalData && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{hotelModalData?.HotelName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="hotel_preview_Img">
                <img src={hotelModalData?.Images?.url} className="img-fluid" />
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Hotels;
