import React, { useEffect, useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import Otpmodal from './otp-modal';
import logo from '../../Assets/images/logo.png'
import { useHistory, useLocation } from 'react-router-dom';
import { LoadToast, updateToast, validation } from '../../CommonHooks/commenfunctions';
import { SendOtp } from '../../Axios/admin.axios';
const cookies = new Cookies();

const Forget = () =>{
    const [email , setEmail] = useState('');
    const [otpcomponent , setOtpcomponent] = useState(false);
    const initial = {
        Email:"",
    
      };
      const [formdata, setFormdata] = useState(initial);

    const [err, setErr] = useState({});
    const [loader, setloader] = useState(false);
    const {push} = useHistory();
    
    const handlecloseotp=()=>{
        setFormdata(initial)
        setOtpcomponent(false)
        setloader(false)


    }



    const onchange = (e) => {
        const { id, value } = e.target;
        setFormdata({ ...formdata, ...{ [id]: value } });
      };

    const handlesubmit=async()=>{

        setloader(true)
        const id = LoadToast();
        let err = await validation(Object.keys(initial), formdata);
        
        if (Object.keys(err)?.length > 0) {
          updateToast(id, "fix");
          setErr(err);
          setloader(false)
    
          return;
        }
        setErr({});
    
        var { status, msg } = await SendOtp(formdata);
        setloader(false)
    
        updateToast(
          id,
          msg,
          status
        );
    if(status=='success'){
        setOtpcomponent(true)
   
    }
                
            }
      

    return(
        <div className='adminlogin'>
             <div className='container container-theme'>
                <div className='row justify-content-center mh-100vh align-items-center m-sm-0 m-3'>
                    <div className='shadowbox col-lg-5 col-xl-4 col-md-6 bg-white br-10'>
                            <div className='py-5'>
                                <h3 className='px-lg-5 px-3  mb-4 text-center'>Forgot Pasword?</h3>
                                <div className='text-center'>
                                <img src={logo} className='img-fluid'/>
                                </div>
                                {otpcomponent &&
                                <Otpmodal show={otpcomponent} handleClose = {()=>{handlecloseotp()}} email={formdata?.Email} ></Otpmodal>
                                
                                }
                                <div className='px-lg-5 px-3 h55 pt-5'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Email"
                                        className="mb-3" >
                                        <Form.Control id ='Email' type="email" placeholder="name@example.com" value={formdata?.Email}
 onChange={onchange} />
                                    </FloatingLabel>
                                    {err?.Email && (
                      <p className="text-left err_msg_txt">{err?.Email}</p>
                    )}
                                    
                                   <div className='mt-4 text-center'>
{                 !loader ? 
<button className='btn btn-theme' onClick={handlesubmit}>Submit</button>
:
<button className='btn btn-theme' disabled>Processing...</button>

}                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
             </div>
        </div>
    )
}

export default Forget