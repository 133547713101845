import React, { useEffect, useState } from 'react';
import { Button, FloatingLabel, Form,InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import logo from '../../Assets/images/logo.png'
import { LoadToast, updateToast, validation } from '../../CommonHooks/commenfunctions';
import { Login } from '../../Axios/admin.axios';
import { toast } from 'react-toastify';
// import { useNavigate } from "react-router-dom";

const cookies = new Cookies();


const AdminLogin = ({stateemail}) =>{
    // const navigate = useNavigate();
    const history = useHistory();


    const initial = {
        Email: "",
        Password:"",
        Path:"login"
      };
      const [formdata, setFormdata] = useState(initial);
      const [err, setErr] = useState({});

    const[showpass, setShowpass] = useState(false)
    const [loader, setloader] = useState(false);


useEffect(()=>{
    if(cookies.get("admin-email"))
    {
        history.push("/");
       
    }
},[])

const onchange = (e) => {
    setErr({})
    const { id, value } = e.target;
    setFormdata({ ...formdata, ...{ [id]: value } });
  };



    const submit = async () => {
        setloader(true)
        const id = LoadToast();
        let err = await validation(Object.keys(initial), formdata);
        
        if (Object.keys(err)?.length > 0) {
          updateToast(id, "fix");
          setErr(err);
          setloader(false)
    
          return;
        }
        setErr({});
        
        let { status, msg,data } = await Login(formdata);
        updateToast(
            id,
            msg,
            status
          );

    
        if (status =='success') {

            if(data?.Role == 'subadmin' && data?.Verify==false){
                    history.push("/change-password")
                    cookies.set("email-data",formdata?.Email)
                    cookies.set("otp-data","otp")


            }
            if(data?.Role == 'subadmin' && data?.Status){
                updateToast(id,'You are blcoked by admin..!','error')


        }
            else{

                    cookies.set('admin-email',formdata?.Email, { path: '/' }, { expires: new Date(Date.now() + 600000) });
                    cookies.set('admin-role',data?.Role);
                    stateemail(formdata?.Email)
                    history.push("/")



            }

           } 
      
           else{
            setErr(err);
            setloader(false)
    
    
           }

   
  
      };

    const handleKeyDown = (event)=>{
        if(event?.key === "Enter"){
            console.log("enter")
        }
    }
    return(
        <div className='adminlogin'>
             <div className='container container-theme'>
                <div className='row justify-content-center mh-100vh align-items-center m-sm-0 m-3'>
                    <div className='shadowbox col-lg-5 col-xl-4 col-md-6 bg-white br-10'>
                            <Form>
                            <div className='py-5'>
                                <h3 className='text-center mb-3'>Login</h3>
                                <div className='text-center'>
                                <img src={logo} className='img-fluid'/>
                                </div>
                                <div className='px-lg-5 px-3 mt-4'>
                                    <FloatingLabel
                                        
                                        label="Email"
                                        className="mb-1">
                                        <Form.Control id ="Email" type="email" placeholder="name@example.com" value={formdata?.Email} onChange={onchange} autoComplete='username' />
                                    </FloatingLabel>
                                    {err?.Email && (
                      <p className="text-left err_msg_txt mb-2">{err?.Email}</p>
                    )}
                                    
                                    <InputGroup className="mb-1 password">
                                    <FloatingLabel label="Password">
                                        <Form.Control id ="Password" type= {showpass ? "text" : "password"} placeholder="Password" value={formdata?.Password} onKeyDown = {(e)=>{handleKeyDown(e)}} onChange={onchange} autoComplete='new-password'/>
                                    </FloatingLabel>
                                    

                                    <Button variant="outline-secondary" id="button-addon1" onClick={() => setShowpass(!showpass)}>
                                    <span className={showpass ? 'fa fa-eye' : 'fa fa-eye-slash'}></span>
                                </Button>
                        </InputGroup>
                        {err?.Password && (
                      <p className="text-left err_msg_txt mb-2">{err?.Password}</p>
                    )}


                                 
                                    <Button variant="link" className="nounder adminblue ps-0 f-14" onClick={()=>{history.push("/forgot-password")}}>Forgot Password?</Button>

                                    <div className='mt-4 text-center'>
                                       <button className='btn btn-theme' type='button' onClick={submit}>Sign in</button>
                                    </div>
                                </div>
                                </div>
                                </Form>
                        
                    </div>
                </div>
             </div>
        </div>
    )
}

export default AdminLogin