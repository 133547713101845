import React,{useState , Fragment, useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import DataTable  from '@ashvin27/react-datatable';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import { Faqcategory } from '../../../Axios/admin.axios';
import { LoadToast, Describtion_showing, updateToast } from '../../../CommonHooks/commenfunctions';
import { DeleteModal } from '../../../CommonHooks/Deletemodel';


const TableView = () =>{
    const [faqcat , setFaqcat] = useState([]);
    const [Deletemodel , setDeletemodel] = useState(false);
    const [Record , setRecord] = useState({});


    const {push} = useHistory();

    useEffect(()=>{
        Getallfaqcategories()
      },[])
      
      const Getallfaqcategories=async()=>{
        let {status,data}=await Faqcategory({Type:"get"})
        setFaqcat(data)
      }
  const deletefaq = async (value) => {
    const id = LoadToast();
    let {status,data,msg}=await Faqcategory(
      {
      Id:value?._id,
      Type:"delete",
    }
    )

    updateToast(id, msg, status);

if(status == 'success'){
    Getallfaqcategories()
    setDeletemodel(false)

}
  }
  const Hideshow = async (value) => {
    const id = LoadToast();
    let { status, data, msg } = await Faqcategory(
        {
            Id: value?._id,
            Type: "hideshow",
            HideShow:!value?.HideShow
        }
    )

    updateToast(id, msg, status);

    if (status == 'success') {
        Getallfaqcategories()

    }
}


    const columns = [
        {
            key: "sno",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
            cell: (record,index) =>
            <div>{index+1}
            </div>
        },
        {
            key: "Name",
            text: "Name",
            className: "name",
            align: "left",
            sortable: true,

       
                },
        {
            key : "Description",
            text: "Description",
            className: "name",
            align: "left",
            sortable: true,
            cell: (record,index) =>
            <div>{Describtion_showing(record?.Description)}
            </div> 
        },
     
        {
            key: "HideShow",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (
            <div>
                <button onClick={()=>Hideshow(data)} className={data?.HideShow == true ? 'blog_statusBtn deactive' : 'blog_statusBtn active'}>
                      {data?.HideShow ? "Deactive":"Active"}
                    </button>
              
                </div>
                ) 
                   },
        
        {
            key: "action",
            text: "Edit action",
            className: "action",
            width: 100,
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <Fragment>
              
                        <button
                            className="btn btn-primary btn-sm cmn-btn"
                            onClick={() => editRecord(record)}
                            style={{ marginRight: '5px' }}
                            >
                            <i className="fa fa-edit mr-0"></i>
                        </button>
             
                        <button
                            className="btn btn-primary btn-sm cmn-btn"
                            // onClick={() => deletefaq(record)}
                            onClick={() => {setDeletemodel(true);setRecord(record)}}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-trash mr-0"></i>
                        </button>
                    </Fragment>
                );
            }
        }

    ]
   

    const config = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        filename: "Faq category",
        no_data_text: 'No data found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },
        className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter Faqcategory",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first:"<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };
  

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

const editRecord = async(data)=>{
    push("edit-faqcategory" , { data : data});
}


    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Faq Categories</h4>
                <div className='pl-4 text-end mt-3' >
                    <button className='btn btn-theme me-0' onClick={()=> {push('/add-faqcategory')}}>Add</button>
                </div>
             
              
                <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                    
                    <DataTable striped responsive
                        config={config}
                        className={config.className}
                        records={faqcat}
                        columns={columns}
                    />
               </div>


               </div> 

{   Deletemodel&& Record &&  
<DeleteModal
show={Deletemodel}
handleClose={()=>setDeletemodel(false)}
Deletfunction={()=>deletefaq(Record)}
Name={'category'}

/>        

    }
            </div>
        </div>
    )
}


export default TableView