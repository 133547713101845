import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; 
import 'bootstrap/js/src/modal.js';
import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/tooltip.js';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from 'react-router-dom';
import { LoadToast, updateToast, validation } from '../../../CommonHooks/commenfunctions';
import { Blogs, Faq, Faqcategory } from '../../../Axios/admin.axios';
import { isEmpty } from 'validator';
import { toast } from 'react-toastify';
import config from '../../../config/config'


const FormView = () =>{
    
    const initial = {
    Heading: "",
    Content:"",
    Type:"edit",
    Image:"",
    Symbol:"",
    Id:"",
    // Url:""

  };
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  const [Content, setContent] = useState("");

  const { push } = useHistory();
  const location =useLocation()

  useEffect(() => {
    setFormdata({...formdata,...{
      Heading:location?.state?.data?.Heading,
      Content:location?.state?.data?.Content,
      Image:location?.state?.data?.Image,
      Symbol:location?.state?.data?.Symbol,
      Id:location?.state?.data?._id
    }})
    setContent(location?.state?.data?.Content)
  }, []);


  const onchange = (id,value) => {
    if (id == 'Image') {
      Object.values(value).map((item) => {
        if (!item?.type?.includes("image")) {
          return toast.error("Image Only allowed");
        }
        else{
          setFormdata({ ...formdata, ...{ ["Image"]: Object.values(value)[0] } });

        }
      })

    }
    else{
      setErr({})
      setFormdata({ ...formdata, ...{ [id]: value } });
    }

  };
  const handlesubmit = async () => {
    setloader(true)


    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setloader(false)

      return;
    }
    if (isEmpty(Content)) {
        updateToast(id, "fix");
        setErr({Content:"Content is required"});
        setloader(false)
  
        return;
      }

    setErr({});

    formdata.Content=Content

    let { status,msg,data } = await Blogs(formdata);

    updateToast(
      id,
      msg,
      status
    );

    if (status=='success') {
        push("/blog");

       } 
  
       else{
        setErr(err);
        setloader(false)


       }

  };

    return(
        <div id="dashboard" className='showsidebar blog'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Edit Blogs</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>

                        

                        { 
                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Heading"
                                className="mb-3" >
                            <Form.Control type="text" disabled={false}
placeholder="name@example.com" value={formdata?.Heading} onChange={(e)=>onchange('Heading',e.target.value)}
 />
                            </FloatingLabel>
                            {err?.Heading && (
                      <p className="text-left err_msg_txt">{err?.Heading}</p>
                    )}
                        </div>
                        }
             <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">
                
                <FloatingLabel
                  controlId="floatingInput"
                  label="Symbol"
                  className="mb-3"
                >

                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    value={formdata?.Symbol}
                    onChange={(e)=>onchange('Symbol',e.target.value)}
                    disabled={false}
                  />
                </FloatingLabel>
                {err?.Url && (
                      <p className="text-left err_msg_txt">{err?.Url}</p>
                    )}

              </div>

              <div className='upload_img px-4 pt-3 mb-4'>
              <div className="custom_uploadBtn">
              <input type="file" id="file" onChange={(e) => onchange('Image',e.target.files)} />
              </div>
                            {formdata?.Image && (
                               <div className='preview_img mt-2'>
                  <img
                    className="mb-2"
                    src={ formdata?.Image
                    && typeof formdata?.Image == "object"
                        ? window.URL.createObjectURL(formdata?.Image)
                        :
                         `${config.IMG_URL}/blogs/${location?.state?.data?.Symbol}/${formdata?.Image}`

                  }                    width={90}
                  />
                   </div>
                )}

              
             </div>
             
              <div className="px-4">
                <h5 className="mb-3">Content *:</h5>

                <ReactSummernote
                  value={Content}
                  options={{
                    //   lang: 'ru-RU',
                    height: 350,
                    dialogsInBody: true,
                    toolbar: [
                      ["style", ["style"]],
                      ["font", ["bold", "underline", "clear"]],
                      ["fontname", ["fontname"]],
                      ["para", ["ul", "ol", "paragraph"]],
                      ["table", ["table"]],
                      ["insert", ["link", "picture", "video"]],
                      ["view", ["fullscreen", "codeview"]],
                    ],
                  }}
                  onChange={(e) => {
                    setContent(e);
                  }}
                />
              </div>

                      
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/blog")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}


export default FormView