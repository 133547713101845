import React, { useEffect, useState, useRef } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";
import { FloatingLabel, Form, Button, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { Commissions } from "../../../Axios/admin.axios";

import { LoadToast, updateToast, validation } from "../../../CommonHooks/commenfunctions";
const Commission = () => {
  const { push } = useHistory();

  const hotel = useRef(null);



  const [HotelsPercentage, setHotelspescentage] = useState(0);
  const [ActivitiesPercentage, setActivitiespescentage] = useState(0);
  const [showpass, setShowpass] = useState(false);
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);





  useEffect(() => {
    getcommissions()
  }, []);

const getcommissions=async()=>{
  const {status,data,msg}=await Commissions({
    Path:'get'
  })
  
  setHotelspescentage(data?.[0]?.Percentage)

  setActivitiespescentage(data?.[1]?.Percentage)


}
  const onchange = (e) => {
    const {id,value}=e?.target
    if(id=='Hotels'){
      setHotelspescentage(value)

    }
    else{
      setActivitiespescentage(value)

    }
  };
const submit=async(value)=>{
  console.log("statusstatusstatusstatusstatusstatus",value);
  setloader(true)
  const id = LoadToast();

let SendData ={
  Type:value,
  Hotels:HotelsPercentage,
}

let err = await validation(Object.keys({Type:"",Hotels:""}), SendData);

if (Object.keys(err)?.length > 0) {
  updateToast(id, "fix");
  setErr(err);
  setloader(false)

  return;
}
setErr({});


  const {status,data,msg}=await Commissions({
    Path:"edit",
    Percentage:value=='Hotels'?HotelsPercentage:ActivitiesPercentage,
    Type:value,


  })
console.log("statusstatusstatus",status);
  updateToast(id, msg, status);
  setloader(false)


if(status=='success'){
  getcommissions()
  setloader(false)

}
}

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Commission</h4>
          <div className="admin-forms mb-4 ">
            <div className="shadowbox p-4 br-10">
              <div className="row">
                <div className="col-sm-12 mb-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hotel Commission"
                  >
                    <Form.Control ref={hotel} id ='Hotels'type="number" value ={HotelsPercentage} placeholder="demo"  onChange={onchange}/>
                  </FloatingLabel>
                  <div className="mt-3 d-flex align-items-center gap-3 flex-wrap">
                    {/* <Button
                      className="btn-outline-dark"
                    >
                      Edit
                    </Button>  */}
                    <Button className="btn-theme" onClick={()=>submit('Hotels')}
>Save</Button>
                  </div>
                </div>
                {err?.Hotels && (
                      <p className="text-left err_msg_txt mb-2">{err?.Hotels}</p>
                    )}

                {/* <div className="col-sm-12 mb-4">
                  <FloatingLabel
                    // controlId="floatingInput"
                    label="Activity Commission"
                  >
                    <Form.Control
                      ref={activity}
                      id='Activities'
                      type="number"
                      placeholder="demo"
                      onChange={onchange}
                      value ={ActivitiesPercentage}
                    />
                  </FloatingLabel>
                  <div className="mt-3 d-flex align-items-center gap-3 flex-wrap">
                    <Button
                      className="btn-outline-dark"
                      onClick={()=>submit('Activities')}
                    >
                      Edit
                    </Button>
                    <Button className="btn-theme">Save</Button>
                  </div>
                </div> */}
                {/* {err?.Activities && (
                      <p className="text-left err_msg_txt mb-2">{err?.Activities}</p>
                    )} */}

                {/* <div className="col-sm-12 mb-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Flight Commission"
                  >
                    <Form.Control ref={flight} type="text" placeholder="demo" />
                  </FloatingLabel>
                  <div className="mt-3 d-flex align-items-center gap-3 flex-wrap">
                    <Button
                      className="btn-outline-dark"
                      onClick={handleFocusHotel}
                    >
                      Edit
                    </Button>
                    <Button className="btn-theme">Save</Button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commission;
