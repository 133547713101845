import React, { useState } from "react";
import AdminHeader from "./AdminHeader";
import Sidebar from "./Sidebar";
import { FloatingLabel, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { LoadToast, updateToast, validation } from "../../CommonHooks/commenfunctions";
import { ChangePassword } from "../../Axios/admin.axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();


const ChangePasswords = () => {
  const { push } = useHistory();
  const [oldPassword, setOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  
  const initial = {
    NewPassword:"",
    ConfirmPassword:"",
    OldPassword:""
  };
  const [formdata, setFormdata] = useState(initial);
  const [Err, setErr] = useState({});
  const [loader, setloader] = useState(false);

  const onchange = (e) => {
    setErr({})
    const { id, value } = e.target;
    setFormdata({ ...formdata, ...{ [id]: value } });

  };
  const handlesubmit = async () => {
    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    
    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setTimeout(() => {
        setloader(false)

      }, 2000);
      return;
    }
    setErr({});

    if(formdata?.ConfirmPassword!=formdata?.NewPassword){
      updateToast(id, "Confirm password must be the same as the New password...!","error");
      setErr(err);
      setTimeout(() => {
        setloader(false)

      }, 2000);

      return;
    }

    let sendata={
      Password:formdata?.ConfirmPassword,
      Email:cookies.get("admin-email"),
      Type:'changepassword',
      Oldpassword:formdata?.OldPassword
    }
    var { status, msg } = await ChangePassword(sendata);
    updateToast(
      id,
      msg,
      status
    );
    if (status =='success') {
      setTimeout(() => {
        cookies.remove("otp-data");
        cookies.remove("email-data");
        window.location.reload()
      }, 1000);
      setloader(false)

    } else {
      setErr(err);
      setTimeout(() => {
        setloader(false)

      }, 2000);
    }
  }
  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont change_pwd">
          <h4 className="adminblue my-4">Change Password</h4>
          <div className="shadowbox pt-3 p-4 bg-white br-10">
            <div className="row">
              <div className="col-xl-10 h-55 w-full mb-3 mb-lg-4">
                <p className="mb-2">Old Password</p>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Old Password"
                  className=""
                >
                  <Form.Control
                    type={oldPassword ? "text" : "password"}
                    placeholder=""
                    id="OldPassword"
                    value={formdata?.OldPassword}
                    onChange={onchange}

                  />

                  <span
                    onClick={() => setOldPassword(!oldPassword)}
                    className={
                      oldPassword
                        ? "fa fa-eye pwd_hide_icon"
                        : "pwd_hide_icon fa fa-eye-slash"
                    }
                  />
                </FloatingLabel>
                            
                {Err?.OldPassword && (
                      <p className="text-left err_msg_txt">{Err?.OldPassword}</p>
                    )}
              </div>
              <div className="col-xl-10 h-55 w-full mb-3 mb-lg-4">
                <p className="mb-2">New Password</p>
                <FloatingLabel
                  className=""
                  label="New Password"
          
                >
                  <Form.Control
                    type={newPassword ? "text" : "password"}
                    placeholder=""
                    id="NewPassword"
                    value={formdata?.NewPassword}
                    onChange={onchange}
                  />
                  <span
                    onClick={() => setNewPassword(!newPassword)}
                    className={
                      newPassword
                        ? "fa fa-eye pwd_hide_icon"
                        : "pwd_hide_icon fa fa-eye-slash"
                    }
                  />
                </FloatingLabel>
                {Err?.NewPassword && (
                      <p className="text-left err_msg_txt">{Err?.NewPassword}</p>
                    )}
              </div>

              <div className="col-xl-10 h-55 w-full">
                <p className="mb-2">Confirm Password</p>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Confirm Password"
                  className=""
       
                >
                  <Form.Control
                    type={confirmPassword ? "text" : "password"}
                    placeholder=""
                    id="ConfirmPassword"
                    value={formdata?.ConfirmPassword}
                    onChange={onchange}
                  />
                  <span
                    onClick={() => setConfirmPassword(!confirmPassword)}
                    className={
                      confirmPassword
                        ? "fa fa-eye pwd_hide_icon"
                        : "pwd_hide_icon fa fa-eye-slash"
                    }
                  />
                </FloatingLabel>
                {Err?.ConfirmPassword && (
                      <p className="text-left err_msg_txt">{Err?.ConfirmPassword}</p>
                    )}
              </div>
            </div>

            <div className="mt-4 text-start">
            {     !loader?      <button
                        className="btn btn-theme me-2 "
                        onClick={handlesubmit}
                      >
                        Submit
                      </button>
                      :
                      <button
                      className="btn btn-theme me-2 "
                    >
                      Processing...
                    </button>
                      }
              {/* <button
                className="btn btn-theme cancel"
                onClick={() => {
                  push("/blog");
                }}
              >
                Cancel
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswords;
