import { useHistory } from "react-router-dom";
import {  Modal } from "react-bootstrap";

const ReviewViewModal = ({show,onDismiss,Reviewsdata}) => {
  const { push } = useHistory();
  return (
    <Modal
      show={show}
      size="md"
      centered
      scrollable
      className="modal_style review_previewModal"
    >
      <Modal.Header className="border-0 p-3">
        <h5 className="modalhead mb-0">Preview</h5>
        <button
          type="button"
          className="btn close close_modal"
          onClick={() => onDismiss()}
        >
          <span aria-hidden="true">×</span>
          <span class="sr-only">Close</span>
        </button>
      </Modal.Header>

      <Modal.Body>
        <div className="user_details">

          <div className="mb-0">
            <p className="label_txt">Review</p>
            <p className="mb-0 val f-15">
  {Reviewsdata}
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReviewViewModal;
