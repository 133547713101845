import React, { useEffect, useState } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";
import { FloatingLabel, Form, Button, InputGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Select from "react-select";
import { LoadToast, updateToast, validation } from "../../../CommonHooks/commenfunctions";
import { Addadmin } from "../../../Axios/admin.axios";
const AddSubAdmin = () => {
  const { push } = useHistory();
  const location = useLocation();
  const initial = {
    Name:"",
    Email: "",
    Role:"",
    Password:"",
    Type:"add"
  };

  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [passwordshow, setPasswordshow] = useState(false);
  const [loader, setloader] = useState(false);




  const onchange = (e) => {
    setErr({})
    const { id, value } = e.target;
    setFormdata({ ...formdata, ...{ [id]: value } });
  };

  


  const handlesubmit = async () => {

    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    
    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setloader(false)

      return;
    }
    setErr({});
    
    let { status, msg } = await Addadmin(formdata);

    updateToast(
      id,
      msg,
      status
    );

    if (status=='success') {
        push("/Sub-admin")
       } 
  


  };

  const handlecancel = async () => {

  };


  const options = [
    { label: "Thing 1", value: 1 },
    { label: "Thing 2", value: 2 },
  ];
  const options1 = [
    { label: "Subadmin", value: 'subadmin'},
    // { label: "Thing 2" ,value: 2 },
  ];
  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#653883"
          : isSelected
          ? "#653883"
          : isFocused
          ? "#653883"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "38px",
      padding: "0px 10px",
      border: "none",
      backgroundColor: "#fff",
      color: "#fff",
      borderColor: "#BEBEBE",
      borderRadius: "4px",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: "4px",
      backgroundColor: "transparent",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#000",
      };
    },
  };


  // useEffect(() => {
  //   setSelectedOptions([{ label: "All", value: "*" }, ...options]);
  //   setTimeout(function() {
  //     let optList = document.getElementsByClassName("css-1qprcsu-option");
  //     // debugger;
  //     for (let i = 0; i < optList.length; i++) {
  //       let item = optList[i];
  //       let index = i;
  //       addTitle(item, index);
  //     }
  //   }, 100);
  // }, []);

  // function addTitle(item, index) {
  //   let val = item.innerText;
  //   item.title = val;
  // }
  // function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
  //   if (value && value.some((o) => o.value === "*")) {
  //     return `${placeholderButtonLabel}: All`;
  //   } else {
  //     return `${placeholderButtonLabel}: ${value.length} selected`;
  //   }
  // }

  // function onChange(value, event) {
  //   if (event.action === "select-option" && event.option.value === "*") {
  //     this.setState(this.options);
  //   } else if (
  //     event.action === "deselect-option" &&
  //     event.option.value === "*"
  //   ) {
  //     this.setState([]);
  //   } else if (event.action === "deselect-option") {
  //     this.setState(value.filter((o) => o.value !== "*"));
  //   } else if (value.length === this.options.length - 1) {
  //     this.setState(this.options);
  //   } else {
  //     this.setState(value);
  //   }
  // }

console.log("errerr",err);

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Add SubAdmin</h4>
          <div className="admin-forms mb-4 ">
            <div className="shadowbox p-4 br-10">
              <div className="row">
                <div className="col-sm-6 mb-4">
                  <FloatingLabel controlId="floatingInput" label="Name">
                    <Form.Control id='Name' type="text" placeholder="demo" onChange={onchange}/>
                  </FloatingLabel>
                </div>
                {err?.Name && (
                      <p className="text-left err_msg_txt mb-2">{err?.Name}</p>
                    )}
                <div className="col-sm-6 mb-4">
                  <FloatingLabel controlId="floatingInput" label="Email">
                    <Form.Control id='Email' type="text" placeholder="demo" onChange={onchange}/>
                  </FloatingLabel>
                </div>
                {err?.Email && (
                      <p className="text-left err_msg_txt mb-2">{err?.Email}</p>
                    )}
                <div className="col-sm-6 mb-4">
                  <FloatingLabel controlId="floatingInput" label="Password">
                    <Form.Control  id='Password' type={!passwordshow?"password":"text"} placeholder="demo" onChange={onchange}/>
                    <Button variant="outline-secondary" id="button-addon1" onClick={() => setPasswordshow(!passwordshow)}>
                                    <span className={passwordshow ? 'fa fa-eye' : 'fa fa-eye-slash'}></span>
                                </Button>
                  </FloatingLabel>
              
                </div>
                {err?.Password && (
                      <p className="text-left err_msg_txt mb-2">{err?.Password}</p>
                    )}
                <div className="col-sm-6 mb-4">
                  <div className="multi_select_all">
                    {/* <ReactMultiSelectCheckboxes
                      options={[{ label: "All", value: "*" }, ...options]}
                      placeholderButtonLabel="Access "
                      getDropdownButtonLabel={getDropdownButtonLabel}
                      value={selectedOptions}
                      onChange={onChange}
                      setState={setSelectedOptions}
                    /> */}

<Select
                                      onChange={(e) =>
                                        setFormdata({...formdata,...{['Role']:e?.value}})  
                                          }
                                      options={options1}
                                      styles={stylesgraybg}
                                      isSearchable={false}
                                      placeholder="Select Category"
                                      classNamePrefix="customselect"
                                      value={{
                                        value: formdata?.Role,
                                        label: formdata?.Role
                                      }}
                             
                                    />

                  </div>
                  {err?.Roll && (
                      <p className="text-left err_msg_txt mb-2">{err?.Roll}</p>
                    )}
                </div>
              </div>

              <div className="px-4 text-end mt-3 btn_view_oneline">
                <button className="btn btn-theme me-3" onClick={handlesubmit}>
                  Submit
                </button>
                <Button
                  variant="outline-dark"
                  className="f-12 fw-600 outlinebtn"
                  onClick={handlecancel}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubAdmin;
