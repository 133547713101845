import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; 
import 'bootstrap/js/src/modal.js';
import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/tooltip.js';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from 'react-router-dom';
import { LoadToast, updateToast, validation } from '../../../CommonHooks/commenfunctions';
import { Faqcategory } from '../../../Axios/admin.axios';
import { isEmpty } from 'validator';

const FormView = () =>{
    
    const initial = {
    Name: "",
    Description:"",
    Type:"edit",
    Id:"",

  };
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  const [Content, setContent] = useState("");

  const { push } = useHistory();
  const location =useLocation()


  useEffect(() => {
    setFormdata({...formdata,...{
      Id:location?.state?.data?._id,
      Name:location?.state?.data?.Name,
      Description:location?.state?.data?.Description,
      HideShow:location?.state?.data?.HideShow
    }})
  }, []);


  const onchange = (id,value) => {
    setErr({})
    setFormdata({ ...formdata, ...{ [id]: value } });
  };

  const handlesubmit = async () => {
    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setloader(false)

      return;
    }

    setErr({});

    let { status,msg,data } = await Faqcategory(formdata);

    updateToast(
      id,
      msg,
      status
    );

    if (status=='success') {
        push("/faqcategory");

       } 
  
       else{
        setErr(err);
        setloader(false)


       }

  };



    // const handleMultipleImage = async (e) => {
    //     try {
    //         console.log("e.target.images", e.target.files, e.target.files?.length);
    //         let arr = [];
    //        if(e.target.name == "aboutsbanner_01"){
    //         if (e.target.files?.length <= 4) {
    //             if(multipleimage?.length >= 4){
    //                  arr = [...arr, ...e.target.files];
    //                 setMultipleImageView(arr);
    //                 setMultipleImages(arr);
    //             } else {
    //                 setMultipleImageView([...multipleimageview, ...e.target.files])
    //                 setMultipleImages([...multipleimage, ...e.target.files])
    //             }
    //         } else {
    //             setMultipleImageError('choose only 4 imgaes')
    //         }
    //        } else {
    //         if (e.target.files?.length <= 2) {
    //             if(multipleimage?.length >= 2){
    //                  arr = [...arr, ...e.target.files];
    //                 setMultipleImageView(arr);
    //                 setMultipleImages(arr);
    //             } else {
    //                 setMultipleImageView([...multipleimageview, ...e.target.files])
    //                 setMultipleImages([...multipleimage, ...e.target.files])
    //             }
    //         } else {
    //             setMultipleImageError('choose only 4 imgaes')
    //         }
    //        }
    //         // if()
    //     } catch (e) {
    //         console.log("handleMultipleImage_err", e)
    //     }
    // }
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Edit FAQ</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        {/* <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                           { <FloatingLabel
                                controlId="floatingInput"
                                label="Heading"
                                className="mb-3" >
                            <Form.Control type="text" placeholder="name@example.com" defaultValue={heading} readOnly={true} onChange={(e)=>{setHeading(e?.target?.value)}}/>
                            </FloatingLabel>}
                            <div className='col-sm-12 mb-4'>
                            <FloatingLabel controlId="floatingSelect" label="Status *">
                                <Form.Select aria-label="Floating label select example" value={status} onChange={(e)=>setStatus(e?.target?.value)}>
                                    <option value ="" hidden>Select Status</option>
                                    <option value='Active'>Active</option>
                                    <option value='Inactive'>Inactive</option>
                                </Form.Select>
                            </FloatingLabel>
                            </div>
                        </div> */}
                        

                        { 
                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Name"
                                className="mb-3" >
                            <Form.Control type="text" placeholder="name@example.com" value={formdata?.Name} onChange={(e)=>onchange('Name',e.target.value)}
 />
                            </FloatingLabel>
                            {err?.Name && (
                      <p className="text-left err_msg_txt">{err?.Name}</p>
                    )}
                        </div>
                        }
             <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">
                
                <FloatingLabel
                  controlId="floatingInput"
                  label="Describtion"
                  className="mb-3"
                >

                  <Form.Control
                    id="Description"
                    type="email"
                    placeholder="name@example.com"
                    value={formdata?.Description}
                    onChange={(e)=>onchange('Description',e.target.value)}
                  />
                </FloatingLabel>
                {err?.Description && (
                      <p className="text-left err_msg_txt">{err?.Description}</p>
                    )}

              </div>
                      
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/manage-cms")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}


export default FormView