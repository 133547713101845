import React, { useState, Fragment, useEffect } from "react";

import { FloatingLabel, Form, Button } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import logo from "../../Assets/images/logo.png";
import {
  LoadToast,
  updateToast,
  validation,
} from "../../CommonHooks/commenfunctions";
import { ChangePassword } from "../../Axios/admin.axios";
const cookies = new Cookies();

const Changepassword = () => {
  const [cms, setCms] = useState([]);

  const initial = {
    NewPassword: "",
    ConfirmPassword: "",
  };

  const [formdata, setFormdata] = useState(initial);
  const [Err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  const { push } = useHistory();
  const [showpass, setShowpass] = useState(false);
  const [showpassold, setShowpassold] = useState(false);

  useEffect(() => {
    if (!cookies.get("otp-data")) {
      push("/login");
    }
  });

  // useEffect(() => {
  //   async function fetchdata() {
  //     console.log("");
  //     await getallcmshook().then((result) => {
  //       console.log("get all crm hook result", result?.data?.data?.record);
  //       result?.data?.data?.record?.map((data, i) => (data.sno = i + 1));
  //       setCms(result?.data?.data?.record);
  //     });
  //   }
  //   fetchdata();
  // }, []);

  const columns = [
    {
      key: "sno",
      text: "Id",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "identifier",
      text: "Identifier",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "content",
      text: "Content",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "name",
      align: "left",
      sortable: true,
      // cell: (data) =>(data?.status  == "active" ?"Active" : "InActive")
    },

    {
      key: "action",
      text: "Edit action",
      className: "action",
      width: 100,
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => editRecord(record)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit mr-0" />
            </button>

            <button
              className="btn btn-primary btn-sm"
              onClick={() => deactivatecms(record)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-trash mr-0" />
            </button>
          </Fragment>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Users",
    no_data_text: "No user found!",
    button: {
      excel: true,
      print: true,
      csv: true,
    },
    className: "table",
    language: {
      length_menu: "Show _MENU_",
      filter: "Filter User",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "<<",
        previous: "<",
        next: ">",
        last: ">>",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

  const editRecord = async (data) => {
    push("edit-cms", { state: data, data: data });
  };

  const deactivatecms = async (data) => {
    console.log("records_data", data, data?.status);
    let datas = {
      id: data?._id,
      status: data?.status == "active" ? "Inactive" : "active",
    };
    // await deactivatecmshook(datas).then((result) => {
    //   console.log("deactivate cms hook result", result);
    //   window.location.reload();
    // });
  };

  const onchange = (e) => {
    setErr({});
    const { id, value } = e.target;
    setFormdata({ ...formdata, ...{ [id]: value } });
  };

  const handlesubmit = async () => {
    setloader(true);
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);

    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setTimeout(() => {
        setloader(false);
      }, 2000);
      return;
    }
    setErr({});
    if (formdata?.ConfirmPassword != formdata?.NewPassword) {
      updateToast(id, "Confirm password must be the same as the New password", "error");
      setErr(err);
      setTimeout(() => {
        setloader(false);
      }, 2000);

      return;
    }

    let sendata = {
      Password: formdata?.ConfirmPassword,
      Email: cookies.get("email-data"),
    };
    var { status, msg } = await ChangePassword(sendata);
    updateToast(id, msg, status);
    if (status == "success") {
      setTimeout(() => {
        cookies.remove("otp-data");
        cookies.remove("email-data");
        push("/login");
      }, 2000);
      setloader(false);
    } else {
      setErr(err);
      setTimeout(() => {
        setloader(false);
      }, 2000);
    }
  };

  return (
    // <div id="dashboard" className="showsidebar">
    //   <div id="sidebar">
    //     <Sidebar />
    //   </div>
    //   <div className="headandcont">
    //     <div className="adminheader">
    //       <AdminHeader />
    //     </div>
    //     <div className="cont">
    //       <h4 className="adminblue my-4">Change Password</h4>
    //       <div className="pl-4 text-end mt-3">
    //       </div>

    <div className="adminlogin">
      <div className="container container-theme">
        <div className="row justify-content-center mh-100vh align-items-center m-sm-0 m-3">
          <div className="shadowbox col-lg-5 col-xl-4 col-md-6 bg-white br-10">
            <div className="py-5">
              <h3 className="px-lg-5 px-3  mb-4 text-center">
                Forgot Pasword?
              </h3>
              <div className="text-center">
                <img src={logo} className="img-fluid" />
              </div>
              <div className="px-lg-4 px-3 h55 pt-4">
                <div className="">
                  <div className="h-55 w-full forgotPwd_inp">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Password"
                      className="mb-0"
                    >
                      <Form.Control
                        type={showpass ? "text" : "password"}
                        placeholder="name@example.com"
                        id="NewPassword"
                        value={formdata?.NewPassword}
                        onChange={onchange}
                      />
                    </FloatingLabel>

                    <Button
                      variant="outline-secondary"
                      id="button-addon1"
                      onClick={() => setShowpass(!showpass)}
                    >
                      <span
                        className={showpass ? "fa fa-eye" : "fa fa-eye-slash"}
                      />
                    </Button>
                  </div>
                  {Err?.NewPassword && (
                    <p className="text-left err_msg_txt">{Err?.NewPassword}</p>
                  )}
                  <div className="h-55 w-full forgotPwd_inp mt-3">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Confirm Password"
                      className="mb-0"
                    >
                      <Form.Control
                        type={showpass ? "text" : "password"}
                        placeholder="name@example.com"
                        id="ConfirmPassword"
                        value={formdata?.ConfirmPassword}
                        onChange={onchange}
                      />
                    </FloatingLabel>
                    <Button
                      variant="outline-secondary"
                      id="button-addon1"
                      onClick={() => setShowpassold(!showpassold)}
                    >
                      <span
                        className={
                          showpassold ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                      />
                    </Button>
                  </div>
                  {Err?.ConfirmPassword && (
                    <p className="text-left err_msg_txt">
                      {Err?.ConfirmPassword}
                    </p>
                  )}
                </div>

                <div className="mt-4 text-center">
                  {!loader ? (
                    <button
                      className="btn btn-theme me-2 "
                      onClick={handlesubmit}
                    >
                      Submit
                    </button>
                  ) : (
                    <button className="btn btn-theme me-2 ">
                      Processing...
                    </button>
                  )}
                  <button
                    className="btn btn-theme cancel"
                    onClick={() => {
                      push("/login");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Changepassword;
