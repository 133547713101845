import React, { useState, Fragment, useEffect } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";
import DataTable from "@ashvin27/react-datatable";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import {  Get_Sociallinks } from "../../../Axios/admin.axios";
import { LoadToast, updateToast } from "../../../CommonHooks/commenfunctions";


const Sociallinks = () => {
  const [Links, setLinks] = useState([]);
  const { push } = useHistory();


  const columns = [
    {
      key: 'sno',
      text: "Id",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record,index) =>
      <div>{index+1}
      </div>

    },
    {
      key: "Website",
      text: "Website",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "Link",
      text: "Link",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
        key: "Status",
        text: "Status",
        className: "name",
        align: "left",
        sortable: true,
        cell: (data) => (<div>
            <button onClick={()=>Hideshowfun(data)} className={data?.Status == true ? 'blog_statusBtn deactive' : 'blog_statusBtn active'}>
                  {data?.Status ? "Deactive":"Active"}
                </button>
          
            </div>
            )

  
    },



    {
      key: "",
      text: "Edit action",
      className: "action",
      width: 100,
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>

            <button
              className="btn btn-primary btn-sm cmn-btn"
              onClick={() => editRecord(record)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit mr-0" />
            </button>


          </Fragment>
        );
      },
    },

  ];


  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "CMS",
    no_data_text: "No data found!",
    button: {
      excel: true,
      print: true,
      csv: true,
    },
    className: "table",
    language: {
      length_menu: "Show _MENU_",
      filter: "Filter Cms",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "<<",
        previous: "<",
        next: ">",
        last: ">>",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };


  const editRecord = async (data) => {
    push("edit-sociallinks", { data: data });
  };


  


  useEffect(()=>{
    GetSocial()
  },[])

  const Hideshowfun = async (value) => {
    const id = LoadToast();
    let { status, data, msg } = await Get_Sociallinks(
        {
            Id: value?._id,
            Type: "statuschange",
            Status:!value?.Status
        }
    )

    updateToast(id, msg, status);

    if (status == 'success') {
        GetSocial()


    }
}


  const GetSocial=async()=>{
    let {status,data}=await Get_Sociallinks({Type:"get"})
    setLinks(data)
  }

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Social Links</h4>
          {/* <div className="pl-4 text-end mt-3">
            <button
              className="btn btn-theme me-0"
              onClick={() => {
                push("/add-sociallinks");
              }}
            >
              Add
            </button>
          </div> */}

          <div className="tablesec shadowbox px-3 mt-3 br-10 mb-5">

            <DataTable
              striped
              responsive
              config={config}
              className={config.className}
              records={Links}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sociallinks;
