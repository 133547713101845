import React, { useState, Fragment, useEffect } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";
import DataTable from "@ashvin27/react-datatable";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { GetSubscribers, Get_Cms, Selectdatachange } from "../../../Axios/admin.axios";
import { LoadToast, updateToast } from "../../../CommonHooks/commenfunctions";

const Subscribers = () => {
  const [subscribers, setsubscribers] = useState([]);
  const { push } = useHistory();
  const [allcheck, setAllcheck] = useState(false);



  const columns = [
    {
      key: 'sno',
      text: "Id",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record, index) =>
        <div>{index + 1}
        </div>

    },
    {
      key: "Email",
      text: "Email",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "Content",
      text: "Content",
      className: "name",
      align: "left",
      sortable: true,
      cell: record =>
        <div>
          <input type="checkbox" checked={record?.Status} onClick={() => Selectchange(record)} /></div>
    },



  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Subscribers",
    no_data_text: "No data found!",
    button: {
      excel: true,
      print: true,
      csv: true,
    },
    className: "table",
    language: {
      length_menu: "Show _MENU_",
      filter: "Filter Subscribers",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "<<",
        previous: "<",
        next: ">",
        last: ">>",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };


  const editRecord = async (data) => {
    push("edit-cms", { data: data });
  };

  useEffect(() => {
    Defaultselct()
  }, [])


  const Defaultselct = async () => {
    let { status, msg, data } = await Selectdatachange({ All: false })
    Getsubscribersdata()


  }

  const Selectchange = async (value) => {
    const id = LoadToast()
    if (value?.target) {
      let { status, msg, data } = await Selectdatachange({ All: value?.target.checked })
      updateToast(id, msg, status)

      if (status == 'success') {
        setAllcheck(!allcheck)

      }

    }
    else {
      var payload = {
        Email: value.Email,
        Status: !value.Status
      }

      let { status, msg, data } = await Selectdatachange(payload);
      updateToast(id, msg, status)

      if (status == 'success') {

        // setTimeout(() => {
        // }, 1000);
      }

    }

    Getsubscribersdata();



  }



  const Getsubscribersdata = async () => {
    let { status, data } = await GetSubscribers({ Path: "get" })
    setsubscribers(data)
  }

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Subscribers</h4>
          {/* <div className="pl-4 text-end mt-3">
            <button
              className="btn btn-theme me-0"
              onClick={() => {
                push("/add-cms");
              }}
            >
              Add
            </button>
          </div> */}

          <div className="tablesec shadowbox px-3 mt-3 br-10 mb-5">


            {subscribers?.length > 0 ? <>

              <button className='btn ms-3 mt-0 mb-3 mt-3 allbtn cmn-btn f-11' type='button' onClick={() => push('/sendmail')}>Send Mail</button>
            </> : <><button className='btn ms-3 mt-0 mb-3 mt-3 f-11 allbtn cmn-btn' type='button'>Send Mail</button></>}

            {subscribers.length > 0 && <>
              <div className="d-flex align-items-center f-15 mb-2 ps-3">
                Select All
                <input type="checkbox" className='selectall ms-2' checked={allcheck} onChange={(e) => Selectchange(e)} />
              </div>

            </>
            }
            <DataTable
              striped
              responsive
              config={config}
              className={config.className}
              records={subscribers}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribers;
