import React, { useEffect, useState } from "react";
import logo from "../../Assets/images/logo.png";
import { Accordion } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import smalllogo from "../../Assets/images/favicon.png";
import hamburgur from "../../Assets/images/Admin/hamburgur.svg";
import Api from "../../Assets/images/API.svg";
import Dashboard from "../../Assets/images/dashboard.svg";

import Manageguestuser from "../../Assets/images/manageguestuser.svg";
import activity from "../../Assets/images/side/activity.svg";
import flight from "../../Assets/images/side/flight.svg";

import hotel from "../../Assets/images/side/hotel.svg";
import cms from "../../Assets/images/side/cms.svg";
import password from "../../Assets/images/side/password.svg";
import support from "../../Assets/images/side/support.svg";
import commission from "../../Assets/images/side/commission.svg";
import agent from "../../Assets/images/side/agent.svg";
import subadmin from "../../Assets/images/side/subadmin.svg";
import Cookies from "universal-cookie";
// import sociallinks from "../../"



const Sidebar = () => {
  const { push } = useHistory();
  const cookies = new Cookies();

  const sidetoggle = () => {
    if (
      document.getElementById("dashboard").classList.contains("showsidebar")
    ) {
      document.getElementById("dashboard").classList.remove("showsidebar");
      document.getElementById("dashboard").classList.add("hidesidebar");
    } else if (
      document.getElementById("dashboard").classList.contains("hidesidebar")
    ) {
      document.getElementById("dashboard").classList.remove("hidesidebar");
      document.getElementById("dashboard").classList.add("showsidebar");
    }
  };

  useEffect(() => {
    if (window.innerWidth > 767) {
      if (document.querySelectorAll(".themescroll li a.active").length > 0) {
        var pagelinkofset = document.querySelectorAll(
          ".themescroll li a.active"
        )[0].offsetTop;
        console.log(pagelinkofset, "pagelinkofset");
        document
          .getElementById("scrollbar_sidebar_id")
          .scrollTo(0, pagelinkofset - 200);
      }
    } else {
      // alert(2)
      if (document.querySelectorAll(".themescroll li a.active").length > 0) {
        if (document.querySelectorAll(".themescroll li a.active").length > 0) {
          var pagelinkofset = document.querySelectorAll(
            ".themescroll li a.active"
          )[0].offsetTop;
          document
            .getElementById("scrollbar_sidebar_id")
            .scrollTo(0, pagelinkofset - 200);
        }
      }
    }
  }, []);
  return (
    <div className="sidebar">
      <div
        className="text-center py-4"
        onClick={() => {
          push("/");
        }}
      >
        <img src={logo} className="adminlogo" />
        <img src={smalllogo} className="adminsmalllogo" />
      </div>
      <button className="togglebtn" onClick={() => sidetoggle()}>
        <img src={hamburgur} className="ham" />
      </button>
      <ul className="themescroll" id="scrollbar_sidebar_id">
        {

          ['admin','subadmin'].includes(cookies.get('admin-role'))?

          <li>
          <NavLink to="/dashboard" className="nounder">
            <img src={Dashboard} />
            <span>Dashboard</span>
          </NavLink>
        </li>
        :
        <></>
        }
      
        {/* hide datas --> Manage Property Type,Manage Privacy Type, Manage Legal, Manage Categories*/}
        {/* <li>
          <NavLink to="/manageadmin" className="nounder" id="manageadmin">
            <img src={Manageadmin} /> <span>Manage admin</span>
          </NavLink>
        </li> */}


     {   ['admin','subadmin'].includes(cookies.get('admin-role'))?   
     <li>
          <NavLink to="/user" className="nounder" id="user">
            <img src={Manageguestuser} /> <span>Users</span>
          </NavLink>
        </li>
        :
        <></>
        }

     {    ['admin','subadmin'].includes(cookies.get('admin-role'))? 
     
     <li>
          <NavLink to="/hotels" className="nounder" id="hotels">
            <img src={hotel} /> <span>Hotels</span>
          </NavLink>
        </li>
        :
        <></>}

       {/* {   ['admin','subadmin'].includes(cookies.get('admin-role'))?
        <li>
          <NavLink to="/activity" className="nounder" id="activity">
            <img src={activity} /> <span>Activity</span>
          </NavLink>
        </li>
        :<></>} */}

      {/* {  
         ['admin','subadmin'].includes(cookies.get('admin-role'))?
      <li>
          <NavLink to="/flight" className="nounder" id="flight">
            <img src={flight} /> <span>Flight</span>
          </NavLink>
        </li>
        :
        <></>} */}

       {   ['admin','subadmin'].includes(cookies.get('admin-role'))?
        <li>
          <NavLink to="/cms" className="nounder" id="cms">
            <img src={cms} /> <span>Cms</span>
          </NavLink>
        </li>
        :
        <></>}
  {   
     ['admin','subadmin'].includes(cookies.get('admin-role'))?
  <li>
          <NavLink to="/faqcategory" className="nounder" id="cms">
            <img src={cms} /> <span>Faq Category</span>
          </NavLink>
        </li>
        :
        <></>}

{   
     ['admin','subadmin'].includes(cookies.get('admin-role'))?
  <li>
          <NavLink to="/faq" className="nounder" id="managefaq">
            <img src={cms} /> <span>Faqs</span>
          </NavLink>
        </li>
        :
        <></>}
              {/* <li>
          <NavLink to="/faq" className="nounder" id="managefaq">
            <img src={Joinus} /> <span>Manage FAQ</span>
          </NavLink>
        </li> */}
     
   {    ['admin','subadmin'].includes(cookies.get('admin-role'))?
       <li>
          <NavLink to="/blog" className="nounder" id="blog">
            <img src={cms} /> <span>Blogs</span>
          </NavLink>
        </li>
        :
        <></>}

           {    ['admin','subadmin'].includes(cookies.get('admin-role'))?
       <li>
          <NavLink to="/sociallinks" className="nounder" id="sociallinks">
            <img src={cms} /> <span>Social Links</span>
          </NavLink>
        </li>
        :
        <></>}
               {   ['admin','subadmin'].includes(cookies.get('admin-role'))? 
       <li>
          <NavLink to="/emailtemplate" className="nounder" id="commission">
            <img src={commission} /> <span>Email Templates

            </span>
          </NavLink>
        </li>
        :
        <></>
        }

{    ['admin','subadmin'].includes(cookies.get('admin-role'))?
       <li>
          <NavLink to="/subscribers" className="nounder" id="cms">
            <img src={cms} /> <span>Subscribers</span>
          </NavLink>
        </li>
        :
        <></>}
{   ['admin','subadmin'].includes(cookies.get('admin-role'))?
        <li>
          <NavLink to="/change-pwd" className="nounder" id="changepasswords">
            <img src={password} /> <span>Password</span>
          </NavLink>
        </li>
        :<></>}

  {/* {    
   ['admin','subadmin'].includes(cookies.get('admin-role'))?  
  <li>
          <NavLink to="/support" className="nounder" id="support">
            <img src={support} /> <span>Support</span>
          </NavLink>
        </li>
        :<></>} */}

       {   ['admin','subadmin'].includes(cookies.get('admin-role'))? 
       <li>
          <NavLink to="/commission" className="nounder" id="commission">
            <img src={commission} /> <span>Commission</span>
          </NavLink>
        </li>
        :
        <></>}

       {/* {
          ['admin','subadmin'].includes(cookies.get('admin-role'))?
       <li>
          <NavLink to="/agent" className="nounder" id="agent">
            <img src={agent} /> <span>Agent</span>
          </NavLink>
        </li>
        :<></>} */}
      {   ['admin','subadmin'].includes(cookies.get('admin-role'))?  
      <li>
          <NavLink to="/Sub-admin" className="nounder" id="subadmin">
            <img src={subadmin} /> <span>Sub Admin</span>
          </NavLink>
        </li>
        :<></>}

      </ul>
    </div>
  );
};

export default Sidebar;
