import React, { useState } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";
import { FloatingLabel, Form, Button } from "react-bootstrap";

import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
import "bootstrap/js/src/modal.js";
import "bootstrap/js/src/dropdown.js";
import "bootstrap/js/src/tooltip.js";
import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from "react-router-dom";

import { LoadToast, isEmpty, updateToast, validation } from "../../../CommonHooks/commenfunctions";
import { SendMailsToSubscribers } from "../../../Axios/admin.axios";
const FormView = (props) => {
  const initial = {
    Subject: "",
   
  };
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  const [Content, setContent] = useState("");

  const { push } = useHistory();

  const onchange = (id,value) => {
    setErr({})
    setFormdata({ ...formdata, ...{ [id]: value } });
  };
  const handlesubmit = async () => {
    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    

    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setloader(false)
      return;
    }
    if (isEmpty(Content)) {
        updateToast(id, 'fix');
        setErr({Content:"Content is requird"});
        setloader(false)
        return;
      }

    setErr({});
    formdata.Content=Content

    let { status, msg,data } = await SendMailsToSubscribers(formdata);

    updateToast(
      id,
      msg,
      status
    );

    if (status=='success') {
        push("/subscribers");

       } 
  
       else{
        setErr(err);
        setloader(false)


       }

  };

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Send Mail</h4>
          <div className="admin-forms mb-4 ">

            <div className="shadowbox p-4 br-10">
              <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">
                
                <FloatingLabel
                  controlId="floatingInput"
                  label="Subject"
                  className="mb-3"
                >

                  <Form.Control
                  id="Subject"
                    type="email"
                    placeholder="name@example.com"
                    value={formdata?.Subject}
                    onChange={(e)=>onchange('Subject',e.target.value)}

                  />
                </FloatingLabel>
                {err?.Subject && (
                      <p className="text-left err_msg_txt">{err?.Subject}</p>
                    )}
                

              </div>



              <div
                className="px-4"
              >
                <h5 className="mb-3">Content *:</h5>

                <ReactSummernote
                  id="Content"
                  value={Content}

                  options={{
                    height: 350,
                    dialogsInBody: true,
                    toolbar: [
                      [
                        "style",
                        ["style", "bold", "italic", "underline", "clear"],
                      ],
                      [
                        "font",
                        [
                          "strikethrough",
                          "superscript",
                          "subscript",
                          "bold",
                          "underline",
                          "clear",
                        ],
                      ],
                      ["fontname", ["fontname"]],
                      ["fontsize", ["fontsize"]],
                      ["table", ["table"]],
                      ["color", ["color"]],
                      ["para", ["ul", "ol", "paragraph"]],
                      ["insert", ["link", "picture", "video"]],
                      ["view", ["fullscreen", "codeview"]][
                        ("height", ["height"])
                      ],
                    ],
                  }}
                  onChange={(e)=>setContent(e)}

                />
              </div>
              {err?.Content && (
                      <p className="text-left err_msg_txt">{err?.Content}</p>
                    )}

              <div className="px-4 text-end mt-3 btn_view_oneline">
                <button className="btn btn-theme me-3" onClick={handlesubmit}>
                  Submit
                </button>
                <Button
                  variant="outline-dark"
                  className="f-12 fw-600 outlinebtn"
                  onClick={() => {
                    push("/cms");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormView;
