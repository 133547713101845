import React, { useState ,useEffect} from 'react';
import {Modal} from 'react-bootstrap'
import Cookies from 'universal-cookie';
import OtpInput from "react-otp-input";
import { useHistory } from 'react-router-dom';
import { LoadToast, updateToast, validation } from '../../CommonHooks/commenfunctions';
import { SendOtp, VerifyOtp } from '../../Axios/admin.axios';
const cookies = new Cookies();



const Otp = ({show,handleClose,email}) =>{
  const TENMINUTESINSECONDS = 600;
   const [Enablebtn, setEnablebtn] = useState(false)
   const [counter, setCounter] = useState(TENMINUTESINSECONDS)
   const [Seconds, setseconds] = useState(0)
   const [Minutes, setminutes] = useState(0)
   const {push} = useHistory();



     const initial = {
      OTP: "",
      Email:email,
    };
    const [formdata, setFormdata] = useState(initial);
    const [err, setErr] = useState({});
    const [loader, setloader] = useState(false);


     useEffect(() => {
      // GetIpAddress()
      if( counter == 0){
       
          setEnablebtn(true);
       
      }
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
     
      const seconds = String(counter % 60).padStart(2, 0);
      setseconds(seconds)
      const minutes = String(Math.floor(counter / 60)).padStart(2, 0);
      setminutes(minutes)


      
      return () => 
      
           clearInterval(timer);
          

     
  }, [counter]);




const resendotp = async () => {
  const id = LoadToast();

  var { status, msg } = await SendOtp(formdata);
  
      updateToast(
        id,
        msg,
        status
      );
};

const verifyotp=async()=>{

  setloader(true)
  const id = LoadToast();
  let err = await validation(Object.keys(initial), formdata);
  
  if (Object.keys(err)?.length > 0) {
    updateToast(id, "fix");
    setErr(err);
    setloader(false)

    return;
  }
  setErr({});

  var { status, msg } = await VerifyOtp(formdata);
  setloader(false)

  updateToast(
    id,
    msg,
    status
  );
if(status=='success'){
// handleClose()
    push("/change-password",email);
    cookies.set("otp-data","otp")
    cookies.set("email-data",email)

}
          
      }


   return(
    <>
<Modal size="md" show={show} centered scrollable  onHide={() => {handleClose()}} className='modal_style thememodal loginmodal'>
      <Modal.Header className='border-0 pb-0'>
      <button type="button" className="btn close close_modal ms-auto" onClick={() =>{handleClose()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

      </Modal.Header>
      <Modal.Body>
       
      <div className='mb-4'>
        <div className=''>
           <h3 className='mb-0 text-center'>Forgot Password </h3>
            
        </div>
        <p className='text-center'><label className='mt-4'>Esssnter Otp</label></p>
        <form action="" className=" mb-4 confirm justify-content-center d-flex gap-2">

                          <div className="otp_input mt-4">
              <OtpInput
                id="OTP"
                value={formdata?.OTP}
                numInputs={6}
                onChange={(e) => setFormdata({ ...formdata, ...{ ['OTP']: e } })}                
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>{" "}
            {err?.OTP && (
                      <p className="text-left err_msg_txt">{err?.OTP}</p>
                    )}
              
         </form>
         <div className="text-center">
      
          
            <button className="btn btn-theme me-2" onClick={resendotp}>Resend</button>
            <button className="btn btn-theme" onClick={verifyotp}>Confirm</button>
           </div>
           <div className=" mt-3 text-center">
            {/* <p className="pr-0"> Otp will expires in <b>  <span>{Minutes}:{Seconds}</span></b></p> */}
            </div> 
           
                
      </div>
      </Modal.Body>

    </Modal>
  </>
   )
}

export default Otp