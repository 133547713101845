import React, { useEffect, useState, Fragment } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";

import DataTable from "@ashvin27/react-datatable";

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";


const Flight = () => {
  const [users, setUsers] = useState([]);
  const [totalrecordcounts, setTotalRecordCounts] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  // const navigate = useNavigate();
  const { push } = useHistory();
  const location = useLocation();
  const [status, setStatus] = useState(false);

  useEffect(() => {
    async function fetchdata() {
      let reqData = {
        page: page,
        limit: limit,
      };
      // await getalluserhook(reqData).then((result) => {
      //   // console.log("get all user result", result);
      //   const records = result?.data?.data?.record;
      //   if(records) {
      //     records.map((a, i) => {
      //     a.serial = i + 1;
      //   });
      //   setUsers(result?.data?.data?.record);
      //   setTotalRecordCounts(result?.data?.data?.count);
      // }
      // });
    }
    fetchdata();
  }, [status]);

  const columns = [
    {
      key: "serial",
      text: "Id",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "place",
      text: "Place",
      className: "name",
      align: "left",
      // sortable: true,
    },
    {
      key: "userdetails",
      text: "User Details",
      className: "name",
      align: "left",
      // sortable: true,
    },
    {
      key: "booking",
      text: "Booking",
      className: "name",
      align: "left",
      // sortable: true,
    },
    // {
    //   key: "place",
    //   text: "Place",
    //   className: "name",
    //   align: "left",
    //   // sortable: true,
    // },
    {
      key: "phonenumber",
      text: "Phone Number",
      className: "name",
      align: "left",
      // sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-primary btn-sm cmn-btn"
              // onClick={() => viewRecord(record)}
              style={{ marginRight: "5px" }}
            >
              <i class="fa fa-eye mr-0" />
            </button>
            <button
              className="btn btn-primary btn-sm cmn-btn"
              onClick={() => editRecord(record)}
              // style={{ marginRight: '5px' }}
            >
              <i className="fa fa-trash mr-0" />
            </button>
            {/* <button
              className="btn btn-primary btn-sm"
              onClick={() => deactivateuser(record)}
            >
              <i className="fa fa-trash"></i>
              {record?.status == "Active" ? (
                <i class="fa fa-eye" />
              ) : (
                <i class="fa fa-eye-slash" />
              )}
            </button> */}
            {/* <button
                            className="btn btn-primary btn-sm"
                            onClick={() => WhiteList(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
          </Fragment>
        );
      },
    },
  ];

  const data = [
    {
      id: 1,
      serial: 1,
      place: "ABC",
      userdetails: "AbcXyz",
      booking: "Test",
      phonenumber: "9876543210",
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Users",
    no_data_text: "No user found!",
    button: {
      excel: true,
      print: true,
      csv: true,
    },
    className: "table",
    language: {
      length_menu: "Show _MENU_",
      filter: "Filter User",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "<<",
        previous: "<",
        next: ">",
        last: ">>",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const editRecord = async (data) => {
    // console.log("edit record data" , data);
    // let payload = {
    //     id :
    // }
    // await edituserhook(data._id).then((result) => {
    //     console.log("edit record result" , result);
    // })
    push("/edituser", { state: data });
  };

  const deactivateuser = async (data) => {
    console.log("deactivate user data", data);
    let reqdata = {
      id: data?._id,
      status: data?.status == "Inactive" ? "Active" : "Inactive",
    };
    // await deactivateuserhook(reqdata).then((result) => {
    //   console.log(
    //     "deactivate user result",
    //     result,
    //     result?.data?.record?.status
    //   );
    //   setStatus(!status);
    //   if (result?.data?.record?.status == "Active") {
    //     window.alert(`${result?.data?.record?.email} Active successfully !`);
    //   } else {
    //     window.alert(`${result?.data?.record?.email} Inactive successfully !`);
    //   }
    //   window.location.reload();
    // });
  };

  const pageChange = async (index) => {
    try {
      console.log("index data", index);
      let reqData = {
        page: index.page_number,
        limit: index.page_size,
        search: index.filter_value,
      };
      // await getalluserhook(reqData).then((result) => {
      //   console.log("get all user result", result);
      //   (result?.data?.data?.record).map((a, i) => {
      //     a.serial = i + 1;
      //   });
      //   setUsers(result?.data?.data?.record);
      // });
      console.log("index_data", index);
    } catch (err) {
      console.log("pageChange_err", err);
    }
  };

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Flight</h4>
          {/* <button className='btn btn-theme me-3' onClick={()=>{push("/admin/adduser")}}>Add</button> */}

          <div className="tablesec shadowbox px-3 mt-3 br-10 mb-5">
            {/* <h6 className="adminblue pt-4 me-2">
              <span className="fa fa-book" /> View User
            </h6> */}
            {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
            <DataTable
              striped
              responsive
              config={config}
              className={config.className}
              // records={users}
              records={data}
              columns={columns}
              dynamic={true}
              total_record={totalrecordcounts}
              onChange={pageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Flight;
