import React,{useState , Fragment, useEffect} from 'react';

import {FloatingLabel, Form,Button, Modal} from 'react-bootstrap'


export const DeleteModal = ({ show, handleClose, Deletfunction,Name }) => {

return(


    <Modal
    show={show}
    onHide={handleClose}
    
          size="medium"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Cancel
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure want to cancel this {Name} ?
            </p>
    
            
            <div className="d-flex align-items-center justify-content-end gap-2" >
            <Button className='cmn-btn mt-2'onClick={()=>Deletfunction()}>Confirm</Button>
    
            <Button  className='cmn-btn mt-2' onClick={()=>handleClose()}>Close</Button>
    
            </div>
          </Modal.Body>
    
        </Modal>

)

}
