import React, { useEffect, useState } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";
import DataTable from "@ashvin27/react-datatable";

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Userdetails } from "../../../Axios/admin.axios";
import {  Name_showing } from "../../../CommonHooks/commenfunctions";
import config from '../../../config/config'
import profile from "../../../Assets/images/user.jpg"

const TableView = () => {
  const [Users, setUsers] = useState([]);
  const { push } = useHistory();
  const location = useLocation();


  useEffect(()=>{
GetUserDetails()
  },[])

  const columns = [
    {
      key: "serial",
      text: "Id",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record, index) =>
      <div>{index + 1}
      </div> 
    },
    {
      key: "name",
      text: "Name",
      className: "name",
      align: "left",
      cell: (record, index) =>
      <div>
        {Name_showing(record?.FirstName+record?.LastName)}
      </div> 
     },

    {
      key: "Email",
      text: "Email",
      className: "name",
      align: "left",
      // sortable: true,
    },
    {
      key: "MobileNumber",
      text: "Mobile Number",
      className: "name",
      align: "left",
      // sortable: true,
    },
    {
      key: "Nationality",
      text: "Nationality",
      className: "name",
      align: "left",
      // sortable: true,
    },
    {
      key: "Profile",
      text: "Profile",
      className: "name",
      align: "left",
      cell: (data) => (
        !data?.Profile ?
        <img src={profile}  alt="img" className="user_profile_img" />
        :
        <img src={`${config.IMG_URL}/user/${data?._id}/profile/${data?.Profile}`} alt="img" className="user_profile_img" />
        
    )
          },
    // {
    //   key: "action",
    //   text: "Action",
    //   className: "action",
    //   width: 100,
    //   align: "left",
    //   // sortable: true,
    //   cell: (record) => {
    //     return (
    //       <Fragment>
    //         <button
    //           className="btn btn-primary btn-sm"
    //           // onClick={() => viewRecord(record)}
    //           style={{ marginRight: "5px" }}
    //         >
    //           <i class="fa fa-eye mr-0" />
    //         </button>
    //         <button
    //           className="btn btn-primary btn-sm"
    //           onClick={() => editRecord(record)}
    //           // style={{ marginRight: '5px' }}
    //         >
    //           <i className="fa fa-trash mr-0" />
    //         </button>
    //         {/* <button
    //           className="btn btn-primary btn-sm"
    //           onClick={() => deactivateuser(record)}
    //         >
             
    //           {record?.status == "Active" ? (
    //             <i class="fa fa-eye" />
    //           ) : (
    //             <i class="fa fa-eye-slash" />
    //           )}
    //         </button> */}
    //         {/* <button
    //                         className="btn btn-primary btn-sm"
    //                         onClick={() => WhiteList(record)}
    //                         style={{ marginRight: '5px' }}>
    //                         <i className="fa fa-edit mr-0"></i>
    //                     </button> */}
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  const data = [
    {
      id: 1,
      serial: 1,
      name: "John",
      email: "john@gmail.com",
      accountdetails: "X023C9023S",
      roles: "Test",
      level: "4",
    },
  ];

  const configs = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Users",
    no_data_text: "No user found!",
    button: {
      excel: true,
      print: true,
      csv: true,
    },
    className: "table",
    language: {
      length_menu: "Show _MENU_",
      filter: "Filter User",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "<<",
        previous: "<",
        next: ">",
        last: ">>",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };



  const GetUserDetails =async()=>{
let {status,msg,data}= await Userdetails({Type:"get"})
setUsers(data)
  }


  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Users</h4>

          <div className="tablesec shadowbox px-3 mt-3 br-10 mb-5">
       
            <DataTable
              striped
              responsive
              config={configs}
              className={configs.className}
              records={Users}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableView;
