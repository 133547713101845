import React, { useEffect, useState, Fragment } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";
import DataTable from "@ashvin27/react-datatable";

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import user from "../../../Assets/images/user.jpg";

import ReviewViewModal from "../ReviewViewModal";
import { AllReviwes } from "../../../Axios/admin.axios";
import { Describtion_showing, Name_showing } from "../../../CommonHooks/commenfunctions";
import configs from '../../../config/config'

const ReviewsList = () => {
  const [users, setUsers] = useState([]);
  const [totalrecordcounts, setTotalRecordCounts] = useState(0);
  const location = useLocation();
  const [reviewmodel, setReviewmodel] = useState(false);
  const [review, setReview] = useState(false);
  const [datas,setData]=useState('')


const Hotelid=location?.state?.data?.split('-')[1]


  const handleViewReviewShow = (value) => {
    setReviewmodel(true);
    setData(value?.Reviews)

  };
  const handleViewReviewClose = () => {
    setReviewmodel(false);
  };
  const columns = [
    {
      key: "serial",
      text: "S.No",
      className: "name",
      align: "center",
      sortable: true,
      cell: (record, index) =>
      <div>{index + 1}
      </div>
    },

    {
      key: "Image",
      text: "Image",
      className: "name",
      align: "center",
      // sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <img
              src={`${configs?.IMG_URL}/user/${record?.Users?._id}/profile/${record?.Users?.Profile}`}
              className="img-fluid"
              style={{ width: "35px", heightt: "35px", borderRadius: "7px" }}
            />
          </Fragment>
        );
      },
    },
    {
      key: "name",
      text: "Name",
      className: "name",
      align: "center",
      // sortable: true,
      cell: (record, index) =>
      <div>{Name_showing(record?.Users?.FirstName+record?.Users?.LastName)}
      </div>
    },
    {
      key: "review",
      text: "Review",
      className: "reviews",
      align: "center",
      // sortable: true,
      cell: (record) => {
        return (
          <div className="reviews_data">
            <p className="mb-0">{Describtion_showing(record?.Reviews)
}</p>
          </div>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "center",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-primary btn-sm cmn-btn"
              onClick={() => handleViewReviewShow(record)}
            >
              <i class="fa fa-eye mr-0" />
            </button>
          </Fragment>
        );
      },
    },
  ];



  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Reviews",
    no_data_text: "No data found!",
    button: {
      excel: true,
      print: true,
      csv: true,
    },
    className: "table",
    language: {
      length_menu: "Show _MENU_",
      filter: "Filter Reviews",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "<<",
        previous: "<",
        next: ">",
        last: ">>",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };


  useEffect(()=>{
    Reviewslist()
    },[])


  const pageChange = async (index) => {
    // try {
    //   console.log("index data", index);
    //   let reqData = {
    //     page: index.page_number,
    //     limit: index.page_size,
    //     search: index.filter_value,
    //   };
    //   await getalluserhook(reqData).then((result) => {
    //     console.log("get all user result", result);
    //     (result?.data?.data?.record).map((a, i) => {
    //       a.serial = i + 1;
    //     });
    //     setUsers(result?.data?.data?.record);
    //   });
    //   console.log("index_data", index);
    // } catch (err) {
    //   console.log("pageChange_err", err);
    // }
  };


const Reviewslist= async ()=>{
var {status,data,msg}=await AllReviwes({Path:'get',Id:Hotelid})
setReview(data)
}
  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Reviews</h4>
          <div className="reviews_listTable tablesec shadowbox px-3 mt-3 br-10 mb-5">
            <DataTable
              striped
              responsive
              config={config}
              className={config.className}
              // records={users}
              records={review}
              columns={columns}
              dynamic={true}
              total_record={totalrecordcounts}
              onChange={pageChange}
            />
          </div>
        </div>
      </div>

      {reviewmodel && datas && (
        <ReviewViewModal show={reviewmodel} onDismiss={handleViewReviewClose} Reviewsdata={datas} />   
      )}
    </div>
  );
};

export default ReviewsList;
