import React, { useState, Fragment, useEffect } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";
import DataTable from "@ashvin27/react-datatable";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { Get_Cms } from "../../../Axios/admin.axios";
import { Describtion_showing } from "../../../CommonHooks/commenfunctions";

const Cms = () => {
  const [cms, setCms] = useState([]);
  const { push } = useHistory();


  const columns = [
    {
      key: 'sno',
      text: "Id",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record,index) =>
      <div>{index+1}
      </div>

    },
    {
      key: "Type",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "Content",
      text: "Content",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "Description",
      text: "Description",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record,index) =>
      <div>{Describtion_showing(record?.Description)}
      </div>
    },
    // {
    //   key: "Status",
    //   text: "Status",
    //   className: "name",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) =>(record?.Status ?"Active" : "InActive")
    // },

    {
      key: "",
      text: "Edit action",
      className: "action",
      width: 100,
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>

            <button
              className="btn btn-primary btn-sm cmn-btn"
              onClick={() => editRecord(record)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit mr-0" />
            </button>

            {/* <button
              className="btn btn-primary btn-sm"
              onClick={() => deletecms(record)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-trash mr-0" />
            </button> */}
          </Fragment>
        );
      },
    },

  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "CMS",
    no_data_text: "No data found!",
    button: {
      excel: true,
      print: true,
      csv: true,
    },
    className: "table",
    language: {
      length_menu: "Show _MENU_",
      filter: "Filter Cms",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "<<",
        previous: "<",
        next: ">",
        last: ">>",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };


  const editRecord = async (data) => {
    push("edit-cms", { data: data });
  };


  


  useEffect(()=>{
    GetallCms()
  },[])

  const GetallCms=async()=>{
    let {status,data}=await Get_Cms({Path:"get"})
    setCms(data)
  }

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">CMS</h4>
          {/* <div className="pl-4 text-end mt-3">
            <button
              className="btn btn-theme me-0"
              onClick={() => {
                push("/add-cms");
              }}
            >
              Add
            </button>
          </div> */}

          <div className="tablesec shadowbox px-3 mt-3 br-10 mb-5">

            <DataTable
              striped
              responsive
              config={config}
              className={config.className}
              records={cms}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cms;
