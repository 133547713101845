import React, { useEffect, useState, Fragment } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";

import DataTable from "@ashvin27/react-datatable";

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { Addadmin, AdminDetails } from "../../../Axios/admin.axios";
import { LoadToast, updateToast } from "../../../CommonHooks/commenfunctions";
const SubAdmin = () => {
  const [Admin, setAdmin] = useState([]);

  const [totalrecordcounts, setTotalRecordCounts] = useState(0);

  const { push } = useHistory();
  

useEffect(()=>{
  GetAdminDetails()
},[])

const Hideshow = async (value) => {
  const id = LoadToast();
  let { status, data, msg } = await Addadmin(
      {
          Id: value?._id,
          Type: "hideshow",
          Status:!value?.Status
      }
  )

  updateToast(id, msg, status);

  if (status == 'success') {
    GetAdminDetails()

  }
}

  const columns = [
    {
      key: "serial",
      text: "S.no",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record,index) =>
      <div>{index+1}
      </div>
    },
    {
      key: "Name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "Email",
      text: "Email",
      className: "name",
      align: "left",
      // sortable: true,
    },
    {
      key: "Role",
      text: "Role",
      className: "name",
      align: "left",
      // sortable: true,
    },

    // {
    //   key: "Access",
    //   text: "Access",
    //   className: "name",
    //   align: "left",
    //   // sortable: true,
    // },
    {
      key: "Status",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      // sortable: true,
      cell: (data) => (<div>
        <button onClick={()=>Hideshow(data)} className={data?.Status == true ? 'blog_statusBtn deactive' : 'blog_statusBtn active'}>
              {data?.Status ? "Deactive":"Active"}
            </button>
      
        </div>
        )
    },
  ];

  const data = [
    {
      id: 1,
      serial: 1,
      name: "John",
      email: "John@gmail.com",
      role: "SubAdmin",
      access: "dashboard/users/hotels/activity/flight",
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Admin",
    no_data_text: "No data found!",
    button: {
      excel: true,
      print: true,
      csv: true,
    },
    className: "table",
    language: {
      length_menu: "Show _MENU_",
      filter: "Filter Admin",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "<<",
        previous: "<",
        next: ">",
        last: ">>",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };


  const pageChange = async (index) => {
    try {
      console.log("index data", index);
      let reqData = {
        page: index.page_number,
        limit: index.page_size,
        search: index.filter_value,
      };
    } catch (err) {
      console.log("pageChange_err", err);
    }
  };
 
  const GetAdminDetails =async ()=>{
    let {status,data}=await AdminDetails({Type:"get"})
    setAdmin(data)
  }
  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Sub Admin</h4>
          <div className="pl-4 text-end mt-3">
            <button
              className="btn btn-theme me-0"
              onClick={() => {
                push("/add-subadmin");
              }}
            >
              Add
            </button>
          </div>

          <div className="tablesec shadowbox px-3 mt-3 br-10 mb-5">
            {/* <h6 className="adminblue pt-4 me-2">
              <span className="fa fa-book" /> View User
            </h6> */}
            <div className="d-flex align-items-center justify-content-end">
            </div>
            <DataTable
              striped
              responsive
              config={config}
              className={config.className}
              records={Admin}
              columns={columns}
              // dynamic={true}
              total_record={totalrecordcounts}
              onChange={pageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubAdmin;
