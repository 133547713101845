import React, { useState, useEffect } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";
import { FloatingLabel, Form, Button } from "react-bootstrap";

import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
import "bootstrap/js/src/modal.js";
import "bootstrap/js/src/dropdown.js";
import "bootstrap/js/src/tooltip.js";
import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from "react-router-dom";
import { LoadToast, isEmpty, updateToast, validation } from "../../../CommonHooks/commenfunctions";
import { Get_Cms, Get_Sociallinks } from "../../../Axios/admin.axios";
import toast from "react-hot-toast";
import configs from '../../../config/config'
const FormView = (props) => {
  const initial = {
    Website:"",
    Link:"",
    Type:"edit",
    Id:"",
    // Image:""
    
  };
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  const [Content, setContent] = useState("");

  const { push } = useHistory();
  const location =useLocation()



  useEffect(() => {
    setFormdata({...formdata,...{
      Id:location?.state?.data?._id,
      Website:location?.state?.data?.Website,
      Link:location?.state?.data?.Link,
      // Image:location?.state?.data?.Image,

    }})
  }, []);


  const onchange = (id,value) => {
    if (id == 'Image') {
      Object.values(value).map((item) => {
        if (!item?.type?.includes("image")) {
          return toast.error("Image Only allowed");
        }
        else{
          setFormdata({ ...formdata, ...{ ["Image"]: Object.values(value)[0] } });

        }
      })

    }
    else{
      setErr({})
      setFormdata({ ...formdata, ...{ [id]: value } });
    }

  };


  const handlesubmit = async () => {
    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setloader(false)

      return;
    }

    setErr({});

    let { status, msg,data } = await Get_Sociallinks(formdata);

    updateToast(
      id,
      msg,
      status
    );

    if (status=='success') {
        push("/sociallinks");

       } 
  
       else{
        setErr(err);
        setloader(false)


       }

  };

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Edit Social Links</h4>
          <div className="admin-forms mb-4 ">

            <div className="shadowbox p-4 br-10">
              <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">
                
                <FloatingLabel
                  controlId="floatingInput"
                  label="Website"
                  className="mb-3"
                >

                  <Form.Control
                  id="Website"
                    type="email"
                    placeholder="name@example.com"
                    value={formdata?.Website}
                    onChange={(e)=>onchange('Website',e.target.value)}
                    disabled={true}

                  />
                </FloatingLabel>
                {err?.Website && (
                      <p className="text-left err_msg_txt">{err?.Website}</p>
                    )}
                
                {/* <div className="col-sm-6 mb-4">
                  <FloatingLabel controlId="floatingSelect" label="Status *">
                    <Form.Select
                      aria-label="Floating label select example"
                      value={status}
                      onChange={(e) => setStatus(e?.target?.value)}
                    >
                      <option value="">Select Status</option>
                      <option>Active</option>
                      <option>Inactive</option>
                    </Form.Select>
                  </FloatingLabel>
                </div> */}
              </div>
              <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">
                
                <FloatingLabel
                  controlId="floatingInput"
                  label="Link"
                  className="mb-3"
                >

                  <Form.Control
                    id="Link"
                    type="email"
                    placeholder="name@example.com"
                    value={formdata?.Link}
                    onChange={(e)=>onchange('Link',e.target.value)}
                  />
                </FloatingLabel>
                {err?.Link && (
                      <p className="text-left err_msg_txt">{err?.Link}</p>
                    )}

              </div>

              {/* <div className='upload_img px-4 pt-3 mb-4'>
              <div className="custom_uploadBtn">
              <input type="file" id="file" onChange={(e) => onchange('Image',e.target.files)} />
              </div>
                            {formdata?.Image && (
                               <div className='preview_img mt-2'>
                  <img
                    className="mb-2"
                    src={ formdata?.Image
                    && typeof formdata?.Image == "object"
                        ? window.URL.createObjectURL(formdata?.Image)
                        :
                         `${configs.IMG_URL}/linklogo/${location?.state?.data?.Website}/${formdata?.Image}`

                  }                    width={90}
                  />
                   </div>
                )}

              
             </div> */}
             {err?.Image && (
                      <p className="text-left err_msg_txt">{err?.Image}</p>
                    )}

              {/* <div
                className="px-4"
                // onDrop={() => console.log("")}
              >
                <h5 className="mb-3">Content *:</h5>

                <ReactSummernote
                  id="Content"
                  value={Content}

                  options={{
                    height: 350,
                    dialogsInBody: true,
                    toolbar: [
                      [
                        "style",
                        ["style", "bold", "italic", "underline", "clear"],
                      ],
                      [
                        "font",
                        [
                          "strikethrough",
                          "superscript",
                          "subscript",
                          "bold",
                          "underline",
                          "clear",
                        ],
                      ],
                      ["fontname", ["fontname"]],
                      ["fontsize", ["fontsize"]],
                      ["table", ["table"]],
                      ["color", ["color"]],
                      ["para", ["ul", "ol", "paragraph"]],
                      ["insert", ["link", "picture", "video"]],
                      ["view", ["fullscreen", "codeview"]][
                        ("height", ["height"])
                      ],
                    ],
                  }}
                  onChange={(e)=>setContent(e)}

                />
              </div>
              {err?.Content && (
                      <p className="text-left err_msg_txt">{err?.Content}</p>
                    )} */}

              <div className="px-4 text-end mt-3 btn_view_oneline">
                <button className="btn btn-theme me-3" onClick={handlesubmit}>
                  Submit
                </button>
                <Button
                  variant="outline-dark"
                  className="f-12 fw-600 outlinebtn"
                  onClick={() => {
                    push("/sociallinks");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormView;
